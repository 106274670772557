import React, { CSSProperties } from 'react';
import classnames from 'classnames';

export const ASPECT_RATIOS = {
  'credit-card': 86 / 54,
  artwork: 16 / 9,
  square: 1,
};

type Ratios = keyof typeof ASPECT_RATIOS;

type Props = {
  ratio?: Ratios;
  className?: string;
  style?: CSSProperties;
};

export const AspectRatio: React.FC<Props> = ({
  ratio = 'artwork',
  className = '',
  style,
  children,
}) => {
  return (
    <div
      className={classnames(`keep-aspect-ratio keep-aspect-ratio--${ratio}`, className)}
      style={style}
    >
      <div className='keep-aspect-ratio__content'>{children}</div>
    </div>
  );
};
