import * as Types from 'types';

export namespace BrowseFilters {
  // Artist Type
  export const LABELED_ARTIST_TYPES: Record<Types.ArtistTypes, string> = {
    curated: 'Curated',
    op: 'Open Platform',
  };

  // Orders
  export const ORDER_TYPES = [
    'date',
    'popular',
    'last_remaining',
    'alphabetical',
    'price',
  ] as const;
  export type OrderTypes = typeof ORDER_TYPES[number];
  export const LABELED_ORDER_TYPES: Record<OrderTypes, string> = {
    date: 'Date published',
    popular: 'Popularity',
    last_remaining: 'Last remaining',
    alphabetical: 'Alphabetical',
    price: 'Most expensive',
  };

  // Medium
  export const MEDIUM_TYPES = ['any', Types.ArtworkType.image, Types.ArtworkType.video] as const;
  export type MediumTypes = typeof MEDIUM_TYPES[number];
  export const LABELED_MEDIUM_TYPES: Record<MediumTypes, string> = {
    any: 'Any',
    image: 'Image',
    video: 'Video',
  };

  // Quality
  export const QUALITY_TYPES = ['any', '4k'] as const;
  export type QualityTypes = typeof QUALITY_TYPES[number];
  export const LABELED_QUALITY_TYPES: Record<QualityTypes, string> = {
    any: 'Any',
    '4k': '4K',
  };

  // Orientation
  export const ORIENTATION_TYPES = ['any', 'portrait', 'landscape'] as const;
  export type OrientationTypes = typeof ORIENTATION_TYPES[number];
  export const LABELED_ORIENTATION_TYPES: Record<OrientationTypes, string> = {
    any: 'Any',
    'portrait': 'Portrait',
    'landscape': 'Landscape',
  };

  export type Tags = string[];
  export type Price = [number, number];
  export const NFT_TYPES = ['true', null] as const;
  export type NFTTypes = typeof NFT_TYPES[number];
}

export type ArtworkListConfigurationParams = {
  medium: BrowseFilters.MediumTypes;
  quality: BrowseFilters.QualityTypes;
  type: Types.ArtistTypes;
  order: BrowseFilters.OrderTypes;
};

export const ARTWORK_LIST_RESET_PARAMS: {
  medium: BrowseFilters.MediumTypes;
  quality: BrowseFilters.QualityTypes;
  tags: BrowseFilters.Tags;
  price?: BrowseFilters.Price;
  nft?: BrowseFilters.NFTTypes;
  orientation?: BrowseFilters.OrientationTypes;
} = {
  medium: 'any',
  quality: 'any',
  tags: [],
  price: undefined,
  nft: undefined,
  orientation: 'any'
};

export const ARTWORK_LIST_DEFAULT_PRICE_RANGE: BrowseFilters.Price = [0, 500];
