import { request } from 'utils/request';

import * as Types from 'types';
import { ResponseActivity } from './types';
import { mapResponseUserCardToUserCard } from 'features/users/api';

export type ActivityTypes = 'primary' | 'trade' | 'profile';
export type ReceiverTypes = 'receiver_user' | 'receiver_artist' | 'receiver_artwork';

export const mapResponseActionToAction = ({
  id,
  body,
  artwork_id,
  user_card,
  timestamp = 0,
}: ResponseActivity): Types.Action => ({
  id,
  body,
  artworkId: artwork_id,
  userCard: mapResponseUserCardToUserCard(user_card),
  timestamp: timestamp * 1000,
});

export type RequestActionParams =
  | {
      type: ActivityTypes;
      receiver?: never;
      receiverId?: never;
    }
  | {
      type: ActivityTypes;
      receiver: ReceiverTypes;
      receiverId: number;
    };

type RequestAction = (params: RequestActionParams) => Promise<Types.Action[]>;

export const requestActions: RequestAction = async ({ type, receiver, receiverId }) => {
  type Params = {
    filter: ('type' | ReceiverTypes)[];
    type: ActivityTypes;
    receiver_user?: number;
    receiver_artist?: number;
    receiver_artwork?: number;
  } & Types.PaginationParams;

  const DEFAULT_PAGE_SIZE = 24;

  const params: Params = {
    type,
    filter: receiver ? ['type', receiver] : ['type'],
    per_page: DEFAULT_PAGE_SIZE,
    ...(receiver && { [receiver]: receiverId }),
  };

  const { data } = await request.get<ResponseActivity[]>(`/api/internal/actions`, { params });
  return data.map(mapResponseActionToAction);
};
