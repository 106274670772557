import { action } from 'typesafe-actions';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

import * as Types from 'types';

import { getList } from './selectors';
import { ListsActionTypes } from './types';

const PER_PAGE = 12;

/**
 * Creates empty list if doesn't exist and
 * sets isFetching to true.
 */
export const startListFetch = (listId: string) =>
  action(ListsActionTypes.START_LIST_FETCH, listId);

/**
 * Updates list with data.
 */
export const receiveList = (
  props: Pick<Types.List<any>, 'id' | 'ids' | 'all' | 'type'>,
) =>
  action(
    ListsActionTypes.RECEIVE_LIST,
    pick(props, ['id', 'ids', 'all', 'type']),
  );

/**
 * Appends list with new data.
 *
 * @param listId list ID
 * @param ids IDs to append
 * @param all does this list contain all data?
 */
export const appendList = (
  listId: string,
  ids: number[],
  all: boolean = false,
) =>
  action(ListsActionTypes.APPEND_LIST, {
    id: listId,
    ids,
    all,
  });

/**
 * Marks list as being refreshed
 *
 * @param listId
 * @param isFetching
 */
export const refreshList = (listId: string, isFetching?: boolean) =>
  action(ListsActionTypes.REFRESH_LIST, { id: listId, isFetching });

// Export all non-thunk actions for use in reducer
export const Actions = {
  startListFetch,
  receiveList,
  appendList,
  refreshList,
};

export type FetchNextPageOptions<T> = {
  /**
   * Existing list data be replaced.
   * List will be marked as "refreshing".
   *
   * @default false.
   */
  refresh?: boolean;

  /**
   * Which page to fetch. If not specified then
   * page will be automatically be determined from
   * perPage and list.ids.length
   */
  initialFetch?: boolean;

  /**
   * Optional receiver to receive the data returned
   * by the fetcher.
   */
  receiver?: (objects: T[]) => void;

  /**
   * Items list page
   */
  perPage?: number;
};

/**
 * Write received ids from fetcherMethod to list.
 */
export const fetchNextPage =
  <T extends Types.Identifiable>(
    listId: string,
    fetcherMethod: (props: Types.PaginationProps) => Promise<T[]>,
    options: FetchNextPageOptions<T>,
  ): Types.DefaultThunkAction =>
  (dispatch, getState) => {
    const perPage = options.perPage || PER_PAGE;
    const list = getList(getState(), { listId });
    const isListAlreadyFetching = list.isFetching;
    const isNextPageRequestButAllAlreadyLoaded =
      !options.initialFetch && !options.refresh && list.all;

    if (isListAlreadyFetching || isNextPageRequestButAllAlreadyLoaded) {
      return;
    }

    if (options.refresh) {
      dispatch(refreshList(listId, true));
    } else {
      dispatch(startListFetch(listId));
    }

    // Determine next page to fetch
    let page = 1;
    if (!options.refresh && !options.initialFetch) {
      page = Math.ceil(list.ids.length / perPage) + 1;
    }

    fetcherMethod({
      page,
      perPage,
    }).then((data) => {
      const ids = data.map(({ id }) => id);
      const all = data.length < perPage;

      /**
       * During initial fetch we fetch first page compare it with
       * check existing state. If items no longer match we consider the
       * state to be outdated outdated therefore replacing it with
       * received data. In future we could add a more comprehensive
       * cache busting techique.
       */
      if (options.initialFetch) {
        const isStateOutdated =
          list.ids.length > 0 && isEqual(list.ids.slice(0, ids.length), ids);
        if (isStateOutdated) {
          dispatch(receiveList({ id: listId, ids, all }));
        }
        return;
      }

      // Pass data to optional receiver
      if (options.receiver) {
        options.receiver(data);
      }

      if (options.refresh) {
        dispatch(receiveList({ id: listId, ids, all }));
      } else {
        dispatch(appendList(listId, ids, all));
      }
    });
  };
