import React from 'react';
import classNames from 'classnames';

import { useFollowings } from 'features/followings/hooks/useFollowings';

type Props = {
  playlistId: number;
  className?: string;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
};

export const FollowPlaylistButton: React.FC<Props> = ({
  playlistId,
  className,
  style,
  iconStyle = { left: 12 },
}) => {
  const { isFollowing, follow, unfollow } = useFollowings(playlistId, 'Playlist');

  const styles = { paddingLeft: isFollowing ? 30 : 10, ...style };

  return (
    <button
      onClick={() => {
        if (window.sedition.user.isGuest()) {
          window.location.href = Routes.signup_path({
            sticky_intent: 'follow_playlist',
            sticky_intent_id: playlistId,
          });
          return;
        }

        if (isFollowing) {
          unfollow(playlistId);
        } else {
          follow(playlistId);
        }
      }}
      style={styles}
      className={classNames('pos-rel', className)}
    >
      <span
        style={iconStyle}
        className={classNames('translate-into-middle-y', {
          'icon-checkmark': isFollowing,
        })}
      />
      <span>{isFollowing ? 'Following' : 'Follow'}</span>
    </button>
  );
};
