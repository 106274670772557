import { useSelector, useDispatch } from 'react-redux';

import * as Types from 'types';
import { requestArtworks } from '../api';
import { Actions } from '../actions';

import { LoadQueue } from 'services/LoadQueue';

const loadQueue = new LoadQueue<Types.Artwork>((ids) => {
  return requestArtworks({ ids, filter: 'ids' });
});

export const useArtworkLoader = (
  artworkId?: number,
  cacheKey?: string,
): Types.Artwork | undefined => {
  const artwork = useSelector((state: Types.RootState) => state.artworks.byId[artworkId || 0]);
  const dispatch = useDispatch();

  if (!artwork && artworkId) {
    if (!loadQueue.receiver) {
      loadQueue.setReceiver((artworks) => {
        dispatch(Actions.receiveArtworks(artworks));
      });
    }
    loadQueue.queue([artworkId]);
  }

  return artworkId ? artwork : undefined;
};
