import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';

export function useQueryParam<T = string>(queryParamName: string, queryParamDefaultValue: T): T;

export function useQueryParam(
  queryParamName: string,
  queryParamDefaultValue?: string,
): string | undefined {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const queryParamValue = searchParams.get(queryParamName) || queryParamDefaultValue;
  const [queryParam, setQueryParam] = useState(queryParamValue);

  if (queryParamValue !== queryParam) {
    setQueryParam(queryParamValue);
  }

  return queryParam;
}

export const useNumericQueryParam = (queryParamName: string, queryParamDefaultValue: number) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let numericQueryParamValue: number = parseInt(searchParams.get(queryParamName) || '', 10);
  numericQueryParamValue = Number.isNaN(numericQueryParamValue)
    ? queryParamDefaultValue
    : numericQueryParamValue;

  const [queryParam, setQueryParam] = useState(numericQueryParamValue);

  if (numericQueryParamValue !== queryParam) {
    setQueryParam(numericQueryParamValue);
  }

  return queryParam;
};

export const useEnumQueryParam = <T>(
  queryParamName: string,
  availableValues: readonly T[],
  queryParamDefaultValue: T,
): T => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const queryParamValue = searchParams.get(queryParamName) || queryParamDefaultValue;
  const validatedParamValue = availableValues.includes(queryParamValue as T)
    ? (queryParamValue as T)
    : queryParamDefaultValue;

  const [queryParam, setQueryParam] = useState(validatedParamValue);

  if (validatedParamValue !== queryParam) {
    setQueryParam(validatedParamValue);
  }

  return queryParam;
};

export const useArrayQueryParam = <T extends string>(
  queryParamName: string,
  validValues: string[],
  defaultValue: T[],
): T[] => {
  const location = useLocation();
  const [queryParam, setQueryParam] = useState(defaultValue);

  useEffect(() => {
    const queryParams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });

    const rawValue = queryParams[queryParamName];

    const value = Array.isArray(rawValue)
      ? (rawValue.filter((item) => validValues.includes(item)) as T[])
      : defaultValue;

    setQueryParam(value);
  }, [location, validValues.length]);

  return queryParam;
};
