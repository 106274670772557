import { request } from 'utils/request';

export { createPaymentIntent, updatePaymentIntent } from './paymentIntents';
export { requestPaymentMethods, deletePaymentMethod } from './paymentMethods';

export const createSetupIntent = async () => {
  try {
    const { data } = await request.post<{ client_secret: string }>(
      '/api/internal/stripe/setup_intents',
    );
    return { data, error: undefined };
  } catch (error) {
    return { data: undefined, error: (error as any).message || 'Error' };
  }
};

export const updateCustomer = async (
  userId: number,
  customer: { invoice_settings: { default_payment_method: string } },
) => {
  try {
    await request.put<{ client_secret: string }>(`/api/internal/users/${userId}/stripe/customer`, {
      customer: customer,
    });
    return true;
  } catch (error) {
    return false;
  }
};
