import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { WagmiConfig } from 'wagmi';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import Modal from 'react-modal';

import { configureStore } from 'store/configureStore';
import { configureWagmi } from 'wagmi/configureWagmi';

import { UserProvider } from 'hooks/useCurrentUser';

import { ProfileArworkGrid } from './components/ProfileArtworkGrid';
import { ProfileActivitySlate } from './components/ActivitySlate';
import { UserPlaylists } from './components/UserPlaylists';
import { ConnectWallet } from './components/ConnectWallet';

import { PaymentMethodsManager } from 'apps/account/PaymentMethodsManager';

type Props = {
  userId?: number;
  artistId?: number;
};

const wagmiClient = configureWagmi();
const store = configureStore();
Modal.setAppElement('body');


export const ProfileApp: FC<Props> = ({ userId, artistId }) => (
  <WagmiConfig client={wagmiClient}>
    <Provider store={store}>
      <UserProvider>
        <Router>
          <Route exact path='(/users)?/:slug'>
            {userId && <ProfileActivitySlate userId={userId} artistId={artistId} />}
          </Route>
          <Route path='/:slug/artworks/:pageType?'>
            {userId && <ProfileArworkGrid userId={userId} />}
          </Route>
          <Route path='/:slug/playlists'>{userId && <UserPlaylists userId={userId} />}</Route>
          <Route path='/account/user/settings'>
            <ConnectWallet />
          </Route>
          <Route path='/account/user/payment_methods'>
            {userId && <PaymentMethodsManager userId={userId} />}
          </Route>
        </Router>
      </UserProvider>
    </Provider>
  </WagmiConfig>
);
