import { ActionType } from 'typesafe-actions';

import { ModalState, ModalActionTypes } from './types';

import { Actions } from './actions';
export type ModalAction = ActionType<typeof Actions>;

const INITIAL_STATE: ModalState = {
  errorModalActive: false,
  errorModalMessage: undefined,
  urlToGoAfterModalClose: undefined,
};

export const modalsReducer = (
  state = INITIAL_STATE,
  action: ModalAction,
): ModalState => {
  switch (action.type) {
    case ModalActionTypes.SHOW_ERROR_MODAL:
      return {
        ...state,
        errorModalActive: true,
        errorModalMessage: action.payload.message,
        urlToGoAfterModalClose: action.payload.urlToGoAfterModalClose,
      };

    case ModalActionTypes.HIDE_ERROR_MODAL:
      return {
        ...state,
        errorModalActive: false,
      };
  }

  return state;
};
