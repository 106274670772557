import { useEffect, useState, useRef } from 'react';

export const useElement = <T extends {}>(propertyGetter: (el: HTMLElement | null) => T) => {
  const elementRef = useRef(null);
  const [elementValue, setElementValue] = useState<T>();

  useEffect(() => {
    if (elementRef.current) {
      setElementValue(propertyGetter(elementRef.current));
    }
  });

  return {
    elementRef,
    elementValue,
  };
};
