import * as Types from 'types';

import { newList } from './helpers';

export const getList = <T>(
  state: Types.RootState,
  props: {
    listId: string;
    readonly byId?: { readonly [id: number]: T };
  },
): Types.List<T> => {
  let list;
  if (state.lists.byId[props.listId]) {
    list = {
      ...state.lists.byId[props.listId],
    };
  } else {
    list = newList(props.listId);
  }

  list.objects = [];
  if (props.byId && list.ids.length > 0) {
    for (let i = 0; i < list.ids.length; i++) {
      let id = list.ids[i];
      if (props.byId[id]) {
        list.objects.push(props.byId[id]);
      } else {
        list.objects = [];
        break;
      }
    }
  }

  return list;
};
