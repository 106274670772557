import { useState, useEffect } from 'react';

import { requestPaymentMethods, deletePaymentMethod, updateCustomer } from 'features/stripe/api';

import * as Types from 'types';

export const usePaymentMethods = (userId: number) => {
  // State
  const [state, setState] = useState<'initial-fetch' | 'ready' | 'error' | 'refreshing'>(
    'initial-fetch',
  );
  const [data, setData] = useState<Types.PaymentMethod[]>([]);

  // Methods
  const detach = async (paymentMethodId: string) => {
    setData((currentData) => [...currentData.filter((item) => item.id !== paymentMethodId)]);

    try {
      deletePaymentMethod(paymentMethodId);
    } catch (e) {
      bugsnag.notify(e);
    } finally {
      await fetch();
    }
  };

  const fetch = async () => {
    if (state === 'ready' || state === 'error') {
      setState('refreshing');
    }

    try {
      const data = await requestPaymentMethods(userId);
      setData(data);
    } catch {
      setState('error');
    } finally {
      setState('ready');
    }
  };

  const setDefault = async (paymentMethodId: string) => {
    setData((paymentMethods) =>
      [...(paymentMethods || [])].map((paymentMethod) => ({
        ...paymentMethod,
        default: paymentMethod.id == paymentMethodId,
      })),
    );
    try {
      await updateCustomer(userId, {
        invoice_settings: { default_payment_method: paymentMethodId },
      });
    } catch (e) {
      bugsnag.notify(e);
    } finally {
      fetch();
    }
  };

  // Fetch on mount
  useEffect(() => {
    fetch();
  }, []);

  return {
    paymentMethods: data,
    paymentMethodsState: state,
    detachPaymentMethod: detach,
    setDefaultPaymentMethod: setDefault,
    fetchPaymentMethods: fetch,
  };
};
