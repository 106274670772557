export const updateParam = (
  paramValue: string | string[] | null | undefined,
  removeValues: string[],
  addValues: string[],
) => {
  let value: string[] = [];
  if (Array.isArray(paramValue)) {
    value = paramValue;
  } else if (typeof paramValue === 'string') {
    value = [paramValue];
  } else {
    value = [];
  }
  value = value.filter((item) => !removeValues.includes(item));
  return [...value, ...addValues];
};
