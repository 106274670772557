import React, { ReactNode, FC, useState, useEffect, MouseEvent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';
import * as Types from 'types';
import { useElement } from 'hooks/useElement';

type Props<T> = {
  title: string;
  pageSize: number;
  items: T[];
  renderComponent: (item: T) => ReactNode;
};

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 1,
};

const transitionStyles: Record<TransitionStatus, any> = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 1 },
  unmounted: { opacity: 1 },
};

export function GenericSlate<T extends Types.Identifiable>({
  renderComponent,
  pageSize,
  items,
  title,
}: PropsWithChildren<Props<T>>) {
  const [page, setPage] = useState(1);
  const [futurePage, setFuturePage] = useState(0);
  const [inProp, setInProp] = useState(false);
  const { elementRef, elementValue } = useElement((el) => el?.clientHeight || 0);

  const totalPages = Math.ceil(items.length / pageSize);

  const paginatedItems =
    totalPages > 1 ? items.slice((page - 1) * pageSize, page * pageSize) : items;

  const nextPage = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setInProp(true);
    setFuturePage(page + 1);
  };

  const previousPage = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setInProp(true);
    setFuturePage(page - 1);
  };

  useEffect(() => {
    setInProp(false);
  }, [page]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className='slate'>
      {items.length !== 0 && (
        <>
          <a
            className={classNames('horizontal-navigation__previous', { 'd-none': page === 1 })}
            onClick={previousPage}
            href='#'
          />
          <a
            className={classNames('horizontal-navigation__next', { 'd-none': page === totalPages })}
            onClick={nextPage}
            href='#'
          />
        </>
      )}
      <h2 className='slate__title'>{title}</h2>
      <Transition in={inProp} timeout={duration} onEntered={() => setPage(futurePage)}>
        {(state: TransitionStatus) => (
          <div
            className='row'
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              minHeight: elementValue,
            }}
            ref={elementRef}
          >
            {paginatedItems.map((item) => (
              <figure
                key={item.id}
                className='artwork artwork-component artwork-type-video mb-gutter col-12 col-lg-4 col-md-4 col-sm-6'
                style={{ width: `${100 / pageSize}%` }}
              >
                {renderComponent(item)}
              </figure>
            ))}
          </div>
        )}
      </Transition>
    </div>
  );
}
