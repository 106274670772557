import React from 'react';

interface BaseProps {
  className?: string;
}

interface PropsWithWidth extends BaseProps {
  width: number;
  height?: never;
}

interface PropsWithHeight extends BaseProps {
  width?: never;
  height: number;
}

type Props = PropsWithWidth | PropsWithHeight;

const ratio = 489 / 160;

export const Visa: React.FC<Props> = ({ className, width, height }) => {
  const size = {
    width: width ? width : Math.round((height as number) * ratio),
    height: height ? height : Math.round((width as number) / ratio),
  };

  return (
    <svg className={className} width={size.width} height={size.height} viewBox='0 0 489 160'>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M197.4 3.7l-24.8 152.8h39.7l25-152.8h-39.9zm-58.3 0l-38 105-4.4-22.6-1-5a126.7 126.7 0 0 0-35.3-44.8c-6-4.7-12-8.8-17.7-12.2l34.5 132.4h41.4L182 3.7h-42.8zM294.4 46c0-17.3 38.7-15 55.7-5.7l5.7-32.8S338.3.9 320 .9c-19.7 0-66.6 8.6-66.6 50.6 0 39.5 55 40 55 60.7 0 20.7-49.3 17-65.6 4l-6 34.2s17.8 8.7 45 8.7c27.1 0 68-14 68-52.3 0-39.8-55.4-43.5-55.4-60.7zM456.7 3.7h-32c-14.8 0-18.4 11.3-18.4 11.3L347 156.5h41.5l8.3-22.7h50.5l4.7 22.7h36.5L456.7 3.7zm-48.5 98.7l21-57.2 11.7 57.2h-32.7z'
          fill='#005BAC'
        />
        <path
          d='M83.8 18S82.2 4.2 64.6 4.2H.8L0 6.8s30.7 6.3 60.2 29.7A119.3 119.3 0 0 1 97.5 87L83.8 18z'
          fill='#F6AC1D'
        />
      </g>
    </svg>
  );
};
