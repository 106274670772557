import { createSelector } from 'reselect';

import * as Types from 'types';

import { newPlaylist, newPlaylistArtwork } from 'features/playlists/helpers';

const listToPlaylistSelectors: Record<string, any> = {};

/**
 * Creates a selector for given list that generates a playlist
 * from list artworks.
 *
 * @param listId
 */
export const createListToPlaylistSelector = (listId: string) => {
  if (listToPlaylistSelectors[listId]) {
    return listToPlaylistSelectors[listId];
  }

  listToPlaylistSelectors[listId] = createSelector(
    (state: Types.RootState) => state.artworks.byId,
    (state: Types.RootState) =>
      state.lists.byId[listId] ? state.lists.byId[listId].ids : [],
    (artworksById, ids) => {
      const playlist = newPlaylist({
        items: ids.map((id) => newPlaylistArtwork(artworksById[id])),
      });
      return playlist;
    },
  );

  return listToPlaylistSelectors[listId];
};

/**
 * Find first editions playlist or return a blank one.
 */
export const selectEditionsPlaylist = createSelector(
  (state: Types.RootState) =>
    Object.values(state.playlists.byId).find(
      (playlist) => playlist.type === 'Editions',
    ),
  (state: Types.RootState) => state.editions.byId,
  (playlist, editionsById): Types.Playlist => {
    if (!playlist) {
      return {
        id: '',
        name: '',
        items: [],
        public: false,
        type: 'Editions',
        userId: 0,
      };
    }
    return {
      ...playlist,
      items: playlist.items.map((item) => ({
        ...item,
        edition: item.editionId ? editionsById[item.editionId] : undefined,
      })),
    };
  },
);

export const selectUserPublicPlaylists =
  (userId: number) =>
  ({ playlists }: Types.RootState) =>
    Object.values(playlists.byId).filter((playlist) => {
      return playlist.userId === userId && playlist.public;
    });
