import { request } from 'utils/request';

export const createPaymentIntent = async (priceCents: number) => {
  try {
    const { data } = await request.post<{ client_secret: string }>(
      '/api/internal/stripe/payment_intents',
      {
        payment_intent: { price_cents: priceCents },
      },
    );
    return { data, error: undefined };
  } catch (error) {
    return {
      error: ((error as any)?.message as string) || 'Error occurred, please refresh the page.',
    };
  }
};

export const updatePaymentIntent = async (
  paymentIntentId: string,
  paymentIntentSecret: string,
  paymentIntent: { payment_method: string },
) => {
  try {
    await request.put(`/api/internal/stripe/payment_intents/${paymentIntentId}`, {
      client_secret: paymentIntentSecret,
      payment_intent: { payment_method: paymentIntent.payment_method },
    });
    return {};
  } catch (error) {
    return { error: (error as any)?.message || 'Error occurred, please refresh the page.' };
  }
};
