import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import debounce from 'lodash/debounce';

type Params = qs.ParsedQuery<string|number|undefined>;

export const useUpdateQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  const updateCurrentQueryParams = useCallback((
    newQueryParams: Params,
  ): Params => {
    const currentQueryParams = qs.parse(
      location.search, { arrayFormat: 'bracket' }
    );

    return {
      ...currentQueryParams,
      ...newQueryParams
    };
  }, [location.search]);

  const updateQueryParams = useCallback((params: Params) => {
    const query = updateCurrentQueryParams(params);

    const queryString = qs.stringify(
      query, { arrayFormat: 'bracket' }
    );

    history.push(`${location.pathname}?${queryString}`);
  }, [updateCurrentQueryParams]);

  const updateQueryParamsDebounced = useCallback(
    debounce(
      updateQueryParams,
      50,
      {
        leading: false,
        trailing: true,
      }
    ),
    [updateQueryParams],
  );

  return {
    updateQueryParams,
    updateQueryParamsDebounced
  };
};
