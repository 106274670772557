import { ActionType } from 'typesafe-actions';

import { EditionsActionTypes, EditionsState } from './types';

export const INITIAL_STATE: EditionsState = {
  byId: {},
  isFetching: false,
  isInitial: true,
};

// Actions & their type
import { Actions } from './actions';
export type EditionsAction = ActionType<typeof Actions>;

export const editionsReducer = (state = INITIAL_STATE, action: EditionsAction) => {
  switch (action.type) {
    case EditionsActionTypes.SHOW_GIFT_EDITION:
      return {
        ...state,
        giftEditionId: action.payload,
      };

    case EditionsActionTypes.REQUEST_EDITIONS:
      return {
        ...state,
        isFetching: true,
      };

    case EditionsActionTypes.SET_EDITIONS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload,
        },
        isFetching: false,
        isInitial: false,
      };

    case EditionsActionTypes.RESET_EDITIONS:
      return {
        ...state,
        byId: action.payload,
        isFetching: false,
        isInitial: false,
      };

    default:
      return state;
  }
};
