import React, { useEffect, useState } from 'react';

import { Spinner } from 'components/Spinner';
import { PaymentElementForm } from 'components/payments/PaymentElementForm';
import { createSetupIntent } from 'features/stripe/api';

type Props = {
  onPaymentAdded: (paymentMethodId: string) => void;
  returnUrl: string;
};

export const AddPaymentMethodForm: React.FC<Props> = ({ returnUrl, onPaymentAdded }) => {
  const [setupIntentClientSecret, setSetupIntentClientSecret] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    createSetupIntent().then(({ data, error }) => {
      if (data) {
        setSetupIntentClientSecret(data.client_secret);
      } else if (error) {
        setError(error);
      }
    });
  }, []);

  if (error) {
    return <div className='text-error'>{error}</div>;
  }

  if (!setupIntentClientSecret) {
    return (
      <div className='translate-into-middle'>
        <Spinner />
      </div>
    );
  }

  return (
    <PaymentElementForm
      returnUrl={returnUrl}
      ctaLabel='Add Payment Card'
      onSuccess={onPaymentAdded}
    />
  );
};
