import * as Types from 'types';

export enum ListsActionTypes {
  START_LIST_FETCH = '@lists/START_LIST_FETCH',
  APPEND_LIST = '@lists/APPEND_LIST',
  TRUNCATE_LIST = '@lists/TRUNCATE_LIST',
  RESET_LIST = '@lists/RESET_LIST',
  RECEIVE_LIST = '@lists/RECEIVE_LIST',
  REFRESH_LIST = '@lists/REFRESH_LIST',
}

export interface ListsState {
  readonly byId: {
    [id: string]: Types.List<any>;
  };
}
