const MAX_IDS_PER_REQUEST = 100;
const DEFAULT_DELAY = 50; // ms

/**
 * Batches multiple requests into one.
 * Eg. get(1); get(2); get(3) results in a single
 * GET /something?ids=1,2,3 request
 */

export class LoadQueue<T extends { id: number }> {
  receiver?: (items: T[]) => void;
  _fetch: (ids: number[]) => Promise<T[]>;
  _pendingQueue: number[] = [];
  _loadingQueue: number[] = [];
  _pendingQueueTimeout: number | undefined;
  _delay: number;

  constructor(fetch: (ids: number[]) => Promise<T[]>) {
    this._fetch = fetch;
    this._fetcher = this._fetcher.bind(this);
    this._delay = DEFAULT_DELAY;
  }

  _fetcher() {
    let ids: number[] = [];
    // Split into multiple requests if we have a lot of
    // IDs to load.
    if (this._pendingQueue.length > MAX_IDS_PER_REQUEST) {
      ids = this._pendingQueue.slice(0, MAX_IDS_PER_REQUEST);
      this._pendingQueue = this._pendingQueue.slice(MAX_IDS_PER_REQUEST);
      setTimeout(this._fetcher, this._delay);
    } else {
      ids = this._pendingQueue;
      this._pendingQueue = [];
      this._pendingQueueTimeout = undefined;
    }

    // Move to loading queue until AJAX completes
    this._loadingQueue.push(...ids);

    if (ids.length === 0) {
      return;
    }

    this._fetch(ids).then((items) => {
      this.receiver && this.receiver(items);
      const loadedIds = items.map((item) => item.id);
      if (ids.length !== loadedIds.length) {
        const missingIds = ids.filter((id) => loadedIds.indexOf(id) === -1);
        missingIds.forEach((missingId) =>
          console.warn('LoadQueue unable to load item with ID', missingId),
        );
      }
      this._loadingQueue = this._loadingQueue.filter(
        (id) => !loadedIds.includes(id),
      );
    });
  }

  setReceiver(receiver: (items: T[]) => void) {
    this.receiver = receiver;
  }

  setDelay(delay: number) {
    this._delay = delay;
  }

  /**
   * Queues new ids for loading.
   */
  queue(ids: number[]) {
    // Remove IDs that were already being loaded
    const newIds = ids.filter((id) => !this._loadingQueue.includes(id));
    // Add only unique IDs to pending queue
    this._pendingQueue = Array.from(
      new Set([...newIds, ...this._pendingQueue]),
    );

    if (!this._pendingQueueTimeout) {
      // Schedule processing if not already scheduled
      this._pendingQueueTimeout = window.setTimeout(this._fetcher, this._delay);
    }
  }
}
