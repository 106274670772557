import React, { FC, createRef } from 'react';

import { useParams } from 'react-router-dom';

import { PER_PAGE } from 'apps/browse/components/BrowseArtworks/helpers';

import { PaginatedGrid } from 'components/PaginatedGrid';
import { AtomicArtwork } from 'components/artworks/AtomicArtwork';

import { NoResults } from 'apps/browse/components/NoResults';

import { usePaginatedResource } from 'hooks/usePaginatedResource';
import { useNumericQueryParam } from 'hooks/useQueryParam';
import { useUpdateQueryParams } from 'hooks/useUpdateQueryParams';

import { pageTypeToAPIQuery } from './helpers';

type Props = {
  userId: number;
};

export const ProfileArworkGrid: FC<Props> = ({ userId }) => {
  const page = useNumericQueryParam('page', 1);
  const { updateQueryParams } = useUpdateQueryParams();
  const { pageType } = useParams<{ pageType: string | undefined }>();
  const filters = pageTypeToAPIQuery(userId, pageType);

  const { isFetching, items, count } = usePaginatedResource(
    '/api/internal/artworks/lists/purchased',
    page,
    PER_PAGE,
    {
      ...filters,
      order: 'date',
      direction: 'desc',
    },
  );

  const contentRef = createRef<HTMLDivElement>();

  return (
    <div className='col' ref={contentRef}>
      {!isFetching && items.length === 0 && <NoResults />}
      <PaginatedGrid
        currentPage={page}
        items={items}
        count={Math.ceil(count / PER_PAGE)}
        renderItem={(item) => (
          <AtomicArtwork
            key={item.id}
            className='col-12 col-lg-3 col-md-4 col-sm-6 mb-gutter'
            artworkId={item.id}
            cacheKey={item.cacheKey}
          />
        )}
        onPageChange={(page) => {
          updateQueryParams({ page: String(page + 1) });
          if (contentRef) {
            const { top } = contentRef!.current!.getBoundingClientRect();

            if (top + window.scrollY < window.scrollY) {
              window.scrollTo(0, 0);
            }
          }
        }}
      />
    </div>
  );
};
