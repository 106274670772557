import * as Types from 'types';

export type ResponseArtworkGroup = {
  id: number;
  title: string;
  slug: string;
};

export const mapResponseArtworkGroupToArtworkGroup = (
  response: ResponseArtworkGroup,
): Types.ArtworkGroup => response;
