import React from 'react';

type Props = {
  entityTitle?: string;
  onReset?: () => void;
};

export const NoResults: React.FC<Props> = ({ onReset, entityTitle = 'artworks' }) => {
  return (
    <div className='text-center py-2'>
      <div className='key-value key-value__value'>No results</div>
      <p className='mb-2'>{'There are no %s matching your filters.'.replace('%s', entityTitle)}</p>
      {onReset && (
        <button className='btn btn--medium btn--border-dark' onClick={onReset}>
          Reset Filters
        </button>
      )}
    </div>
  );
};
