import { ActionType } from 'typesafe-actions';

import * as Types from 'types';

import { ListsActionTypes, ListsState } from './types';
import { newList } from './helpers';

export const INITIAL_SATATE: ListsState = {
  byId: {},
};

// Actions & their type
import { Actions } from './actions';
export type ListsAction = ActionType<typeof Actions>;

export const listsReducer = (
  state = INITIAL_SATATE,
  action: ListsAction,
): ListsState => {
  let list: Types.List<any>;

  switch (action.type) {
    case ListsActionTypes.START_LIST_FETCH:
      list = state.byId[action.payload] || newList(action.payload);

      return {
        ...state,
        byId: {
          ...state.byId,
          [list.id]: {
            ...list,
            isFetching: true,
          },
        },
      };

    /**
     * - Appends ids to list
     */
    case ListsActionTypes.APPEND_LIST:
      list = state.byId[action.payload.id] || newList(action.payload.id);

      return {
        ...state,
        byId: {
          ...state.byId,
          [list.id]: {
            ...list,
            ...action.payload,
            ids: [...list.ids, ...action.payload.ids],
            initial: false,
            isFetching: false,
            isRefreshing: false,
          },
        },
      };

    /**
     * - Replaces list by given list
     */
    case ListsActionTypes.RECEIVE_LIST:
      list = state.byId[action.payload.id] || newList(action.payload.id);

      return {
        ...state,
        byId: {
          ...state.byId,
          [list.id]: {
            ...action.payload,
            initial: false,
            isFetching: false,
            isRefreshing: false,
          },
        },
      };

    /**
     * Clears list and marks it as isRefreshing
     */
    case ListsActionTypes.REFRESH_LIST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...newList(action.payload.id),
            isRefreshing: true,
            isFetching: Boolean(action.payload.isFetching),
          },
        },
      };

    default:
      return state;
  }
};
