import { AnimationProps } from 'framer-motion';

export type UiStates = 'loading' | 'setupError' | 'ready' | 'submitting' | 'error';

export const AnimationFadeOut: AnimationProps['animate'] = {
  opacity: 0,
  transitionEnd: {
    display: 'none',
  },
};

export const AnimationFadeIn: AnimationProps['animate'] = {
  opacity: 1,
  display: 'block',
};

export const defaultErrorMessage =
  'Internal error has occurred, please refresh the page and try again.';

export const uiStatesStyles: Record<
  UiStates,
  { form: AnimationProps['animate']; message: AnimationProps['animate'] }
> = {
  loading: {
    form: AnimationFadeOut,
    message: AnimationFadeIn,
  },
  ready: {
    form: AnimationFadeIn,
    message: AnimationFadeOut,
  },
  submitting: {
    form: { opacity: 0.6, display: 'block' },
    message: AnimationFadeOut,
  },
  setupError: {
    form: AnimationFadeOut,
    message: AnimationFadeIn,
  },
  error: {
    form: AnimationFadeIn,
    message: AnimationFadeOut,
  },
};
