import * as Types from 'types';

export enum ArtworkGroupsActionTypes {
  RECEIVE_ARTWORK_GROUP = '@artwork_groups/RECEIVE',
}

export interface ArtworkGroupsState {
  readonly bySlug: {
    [slug: string]: Types.ArtworkGroup;
  };
}
