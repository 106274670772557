import * as Types from 'types';

export const newList = (listId: string): Types.List<any> => ({
  ids: [],
  id: listId,
  all: false,
  isFetching: false,
  initial: true,
  isRefreshing: false,
  type: undefined,
});
