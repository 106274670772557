import React from 'react';
import ReactDOM from 'react-dom';

import { ProfileApp } from 'apps/profile';
import { initializeAppleAuth } from 'services/appleAuth';

const container = document.getElementsByClassName('js-profile-app')[0] as HTMLElement;
const userId = container?.dataset?.userId ? parseInt(container.dataset.userId, 10) : undefined;
const artistId = container?.dataset?.artistId
  ? parseInt(container.dataset.artistId, 10)
  : undefined;

if (container) {
  ReactDOM.render(<ProfileApp userId={userId} artistId={artistId} />, container);
}

//
// Account
//
$('.js-account-preferences').each(function () {
  const $el = $(this);
  $el.find('.js-account-preferences__show-preferences').on('click', function () {
    $el.find('.js-account-preferences__preferences').show();
    $(this).hide();
  });
});

$('.js-delete-account-modal').each(function () {
  const $modal = $(this);
  const $checkbox = $modal.find('.js-delete-account-modal__accept');
  const $password = $modal.find('.js-delete-account-modal__password');

  const validate = function () {
    const understandChecked = $checkbox.is(':checked');
    const passwordPresent = (($password.val() as string) || '').length > 0;
    $modal
      .find('.js-delete-account-modal__delete-button')
      .prop('disabled', !understandChecked || !passwordPresent);
  };
  $checkbox.on('change', validate);
  $password.on('keyup change blur', validate);
});

$(document).on('click', '.js-link-apple-account', function () {
  const $el = $(this);
  $el.prop({ disabled: true });
  initializeAppleAuth().then((api) => {
    if (api) {
      api.signIn();
    } else {
      $el.prop({ disabled: false });
    }
  });
});

if ($('.js-link-apple-account').length) {
  initializeAppleAuth();
}
