import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

type Props = {
  state: string;
  minHeight?: number;
};

export const ContentAnimator: React.FC<Props> = ({ children, state, minHeight }) => {
  return (
    <div className='pos-rel' style={{ minHeight: minHeight }}>
      <AnimatePresence initial={false}>
        <motion.div
          key={state}
          style={{
            minHeight: minHeight,
          }}
          className='stretch d-flex align-items-center'
          initial={{ y: minHeight, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: minHeight ? -1 * minHeight : undefined, opacity: 0 }}
          transition={{
            duration: 0.5,
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
