import { ActionType } from 'typesafe-actions';

import { arrayToById } from 'services/arrayToById';

import { ArtworksActionTypes, ArtworksState } from './types';

export const INITIAL_STATE: ArtworksState = {
  byId: {},
  activeArtworkId: undefined,
};

// Actions & their type
import { Actions } from './actions';
export type ArtworksAction = ActionType<typeof Actions>;

export const artworksReducer = (
  state = INITIAL_STATE,
  action: ArtworksAction,
): ArtworksState => {
  switch (action.type) {
    case ArtworksActionTypes.RECEIVE_ARTWORKS:
      // payload.artworks is (Types.Artwork | Types.ArtworkDetails)[]
      // When we have Types.Artwork and Types.ArtworkDetails
      // already exists in state, we need to make sure that Types.ArtworkDetails
      // data is not cleared.
      const artworksWithDetails = action.payload.map((artwork) => {
        const preservedArtwork = {
          ...(state.byId[artwork.id] || {}),
          ...artwork,
        };
        return preservedArtwork;
      });
      const byId = arrayToById(artworksWithDetails);

      return {
        ...state,
        byId: {
          ...state.byId,
          ...byId,
        },
      };

    case ArtworksActionTypes.RECEIVE_ARTWORK:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      };

    case ArtworksActionTypes.SET_ACTIVE_ARTWORK:
      return {
        ...state,
        activeArtworkId: action.payload,
      };

    default:
      return state;
  }
};
