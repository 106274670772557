import { useEffect, useState } from 'react';
import { request } from 'utils/request';
import queryString from 'query-string';

import * as Types from 'types';

enum Pagination {
  perPageMax = 100,
  perPage = 9,
  page = 1,
  count = 0,
}

export const usePaginatedResource = (
  url: string,
  page: number = Pagination.page,
  perPage: number = Pagination.perPage,
  query: queryString.ParsedQuery = {},
) => {
  const [isFetching, setIsFetching] = useState(true);
  const [items, setItems] = useState<Types.ListItem[]>([]);
  const [count, setCount] = useState(Pagination.count);

  if (perPage > Pagination.perPageMax) {
    throw `Maximum perPage value is ${Pagination.perPageMax}`;
  }

  const parsed = queryString.parseUrl(url);

  useEffect(() => {
    request
      .get<Types.PageResponse<Types.ListItem[]>>(parsed.url, {
        params: {
          ...parsed.query,
          ...query,
          page: page,
          per_page: perPage,
        },
      })
      .then(({ data }) => {
        setItems(data.data);
        setCount(data.count);
        setIsFetching(false);
      })
      .catch(() => {
        setItems([]);
        setIsFetching(false);
      });
  }, [url, page]);

  return {
    isFetching,
    items,
    count,
  };
};
