import React from 'react';

interface BaseProps {
  className?: string;
}

interface PropsWithWidth extends BaseProps {
  width: number;
  height?: never;
}

interface PropsWithHeight extends BaseProps {
  width?: never;
  height: number;
}

type Props = PropsWithWidth | PropsWithHeight;

const ratio = 160 / 160;

export const AmericanExpress: React.FC<Props> = ({ className, width, height }) => {
  const size = {
    width: width ? width : Math.round((height as number) * ratio),
    height: height ? height : Math.round((width as number) / ratio),
  };

  return (
    <svg className={className} width={size.width} height={size.height} viewBox='0 0 160 160'>
      <g fillRule='nonzero' fill='none'>
        <path fill='#016FD0' d='M0 0h160v86.4l-8 12.3 8 11V160H0V78.6l5-5.7-5-5.5z' />
        <path
          d='M31.3 111.3v-25h26.5l2.9 3.7 2.9-3.8H160v23.3s-2.5 1.7-5.4 1.8h-53.4l-3.2-4v4H87.4v-6.8s-1.4 1-4.5 1h-3.6v5.8h-16l-2.8-3.8-2.9 3.8H31.2zM0 66.4l6-13.9h10.3l3.4 7.8v-7.8h12.8l2 5.6 2-5.6h57.7v2.8s3-2.8 8-2.8h18.7l3.3 7.8v-7.8h10.7l3 4.4v-4.4h10.8v25H138l-2.8-4.4v4.4h-15.8l-1.6-4h-4.3l-1.5 4h-10.7c-4.3 0-7-2.8-7-2.8v2.8H78l-3.2-4v4h-60l-1.6-4H9l-1.6 4H0v-11z'
          fill='#FFF'
        />
        <path
          d='M8.2 56.3L0 75h5.3l1.5-3.8h8.8L17 75h5.4l-8.2-18.8H8.2zm3 4.3l2.6 6.6H8.5l2.7-6.6zM22.5 75V56.2h7.6l4.4 12.2 4.3-12.2h7.5V75h-4.8V61.2l-5 13.8h-4.2l-5-13.8V75zM48.8 75V56.2h15v4.2H53.4v3.3h10.1v3.9H53.4v3.3h10.4V75zM67.5 56.3V75h4.6v-6.7H74l5.4 6.7H85l-6-7c2.5-.1 5-2.3 5-5.7 0-4-3-6-6.4-6H67.5zm4.6 4.1h5.2c1.2 0 2.1 1 2.1 2 0 1.3-1.2 2-2 2H72v-4zM91.3 75h-5V56.2h5zM102.4 75h-1c-4.8 0-7.7-4-7.7-9.3 0-5.5 2.9-9.5 8.9-9.5h4.9v4.5h-5c-2.5 0-4.2 2-4.2 5 0 3.7 2 5.2 4.8 5.2h1.1l-1.8 4.1zM113.2 56.3L105 75h5.3l1.5-3.8h8.8L122 75h5.4l-8.2-18.8h-6.1zm3 4.3l2.6 6.6h-5.3l2.7-6.6zM127.5 75V56.2h5.7l7.3 11.8V56.2h4.5V75h-5.5L132 63v12zM35 108.8V90h15v4.2H39.6v3.2h10.2v4H39.6v3.3H50v4zM110 108.8V90h15v4.2h-10.4v3.2h10.1v4h-10v3.3H125v4zM50.2 108.8l7.2-9.3L50 90h5.7l4.4 5.9 4.4-5.9H70l-7.3 9.4 7.2 9.3h-5.7L60 103l-4.2 5.8zM71.3 90v18.8h4.9v-6h5c4.3 0 7.5-2.2 7.5-6.5 0-3.6-2.5-6.3-6.8-6.3H71.2zm4.9 4.2h5.3c1.4 0 2.4.9 2.4 2.2 0 1.3-1 2.2-2.4 2.2h-5.3v-4.4zM90 90v18.8h4.6V102h1.9l5.4 6.7h5.6l-6-7c2.5-.2 5-2.3 5-5.7 0-4-3-6.1-6.4-6.1H90zm4.6 4.2h5.2c1.2 0 2.1 1 2.1 2 0 1.2-1.2 2-2 2h-5.3v-4zM126.8 108.8v-4.1h9.2c1.3 0 1.9-.8 1.9-1.6s-.6-1.6-2-1.6h-4c-3.7 0-5.7-2.2-5.7-5.6 0-3 1.9-5.9 7.2-5.9h8.9l-2 4.2h-7.6c-1.5 0-2 .8-2 1.6 0 .7.6 1.6 1.7 1.6h4.4c4 0 5.7 2.3 5.7 5.4 0 3.3-2 6-6 6h-9.7zM144.3 108.8v-4.1h9.2c1.3 0 1.9-.8 1.9-1.6s-.6-1.6-2-1.6h-4c-3.7 0-5.7-2.2-5.7-5.6 0-3 1.9-5.9 7.2-5.9h8.9l-2 4.2h-7.6c-1.5 0-2 .8-2 1.6 0 .7.6 1.6 1.7 1.6h4.4c4 0 5.7 2.3 5.7 5.4 0 3.3-2 6-6 6h-9.7z'
          fill='#016FD0'
        />
      </g>
    </svg>
  );
};
