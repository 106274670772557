import * as Types from 'types';

export enum UsersActionTypes {
  RECEIVE_USERS = '@users/RECEIVE_USERS',
}

export interface UsersState {
  readonly byId: {
    [id: number]: Types.UserCard | Types.User;
  };
}
