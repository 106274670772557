import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { SeditionStripeElements } from 'components/payments/SeditionStripeElements';
import { SpinnerWithLabel } from 'components/Spinner';
import { createSetupIntent } from 'features/stripe/api';

import { Form } from './Form';
import { uiStatesStyles, UiStates } from './helpers';

type Props = {
  returnUrl: string;
  ctaLabel?: string;
  onSuccess: (paymentMethodId: string) => void;
};

export const PaymentElementForm: React.FC<Props> = ({ returnUrl, ctaLabel, onSuccess }) => {
  const [uiState, setUiState] = useState<UiStates>('loading');
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [setupError, setSetupError] = useState<string | undefined>();

  useEffect(() => {
    createSetupIntent().then(({ data, error }) => {
      if (data) {
        setClientSecret(data.client_secret);
      } else if (error) {
        bugsnag.notify('Unable to create setup intent');
        setSetupError(error);
      }
    });
  }, []);

  return (
    <div style={{ minHeight: 360 }} className='pos-rel container-fluid px-0 mb-0'>
      <motion.div animate={uiStatesStyles[uiState].form}>
        <SeditionStripeElements clientSecret={clientSecret}>
          <Form
            returnUrl={returnUrl}
            ctaLabel={ctaLabel}
            onSuccess={onSuccess}
            onUiStateChange={setUiState}
          />
        </SeditionStripeElements>
      </motion.div>
      <motion.div
        className='stretch'
        style={{ backgroundColor: 'rgba(255, 255, 255, .6)' }}
        animate={uiStatesStyles[uiState].message}
      >
        <div className='stretch d-flex align-items-center justify-content-center'>
          {setupError ? (
            <div className='text-error fs-16'>{setupError}</div>
          ) : (
            <SpinnerWithLabel>Please wait...</SpinnerWithLabel>
          )}
        </div>
      </motion.div>
    </div>
  );
};
