import React, { FC, useEffect, useState } from 'react';

import { getShortenedAddress } from 'services/nfts';
import { useWagmi } from 'hooks/useWagmi';
import { useCurrentUser } from 'hooks/useCurrentUser';

import { Alert } from 'components/UI/Alert';
import { WalletCard } from 'components/UI/WalletCard';
import { LinkWalletModal } from './components/LinkWalletModal';
import { UnlinkWalletModal } from './components/UnlinkWalletModal';

type Props = {
  showDetails?: boolean;
};

export const LinkWalletContainer: FC<Props> = ({ showDetails = false }) => {
  const currentUser = useCurrentUser();
  const [isLinkModalOpen, setLinkModalOpen] = useState(false);
  const [isUnlinkModalOpen, setUnlinkModalOpen] = useState(false);

  const {
    verifiedAddress,
    account,
    isConnected,
    signer: { isSigning },
  } = useWagmi();

  const shortVerifiedAddress = getShortenedAddress(verifiedAddress) || '';

  const [syncWallets, setSyncWallets] = useState(false);

  useEffect(() => {
    const walletInSync = verifiedAddress
      ? verifiedAddress === account?.address?.toLowerCase()
      : false;
    setSyncWallets(walletInSync);
  }, [verifiedAddress, account]);

  return (
    <div>
      {/* No wallet address found on the user (from api) */}
      {!verifiedAddress ? (
        <>
          <div>
            <div className='d-flex align-items-start align-items-sm-center'>
              <button
                role='button'
                className='btn btn--border-dark btn--medium'
                onClick={(e) => {
                  e.preventDefault();
                  setLinkModalOpen(true);
                }}
                disabled={(isLinkModalOpen && !isConnected) || isSigning}
              >
                Link a Wallet
              </button>
              {isSigning && (
                <div className='ml-1 d-flex'>
                  Wallet linking is in progress, please open your wallet and follow intructions to
                  sign a message.
                </div>
              )}
            </div>
            {showDetails ? (
              <p className='muted mt-mini'>
                Link one of the supported Ethereum wallets to your Sedition account. Doing it allows
                you to export your owned editions to the Ethereum blockchain as NFTs (non-fungible
                tokens) and to trade them on any NFT marketplace, such as OpenSea. Once you link
                your wallet, Sedition will also scan it for any Sedition compatible artworks and add
                them to your Vault. For more information about NFTs, Ethereum and wallets, please
                visit our{' '}
                <a
                  className='link-border'
                  target='_blank'
                  rel='noopener noreferrer nofollow'
                  href={'https://www.seditionart.com/help-centre'}
                >
                  Learn More section
                </a>
                {'.'}
              </p>
            ) : null}
          </div>
        </>
      ) : null}

      {/* When accounts don't match */}
      {account && isConnected && !syncWallets && !isSigning && shortVerifiedAddress && (
        <Alert isClosable={false} variant='error' className='mb-1'>
          <div className='mb-1'>
            <p>
              Your active wallet and the currently linked wallet don't match. Change back to your
              linked wallet or unlink your current account in order to link a new one.
            </p>
            <br />
            <span>
              <b>'Active Wallet: '</b>
              {account?.address}
              <br />
              <b>'Linked Wallet: '</b>
              {verifiedAddress}
            </span>
          </div>
          <button
            className='btn btn--border btn--medium ml-auto mb-mini'
            onClick={(e) => {
              e.preventDefault();
              setUnlinkModalOpen(true);
            }}
          >
            Unlink {shortVerifiedAddress}
          </button>
        </Alert>
      )}

      {/* Wallets match */}
      {verifiedAddress && (
        <>
          <WalletCard address={verifiedAddress} name={currentUser?.name} />
          {showDetails ? (
            <>
              <button
                className='btn btn--border btn--medium mb-mini mt-1'
                onClick={(e) => {
                  e.preventDefault();
                  setUnlinkModalOpen(true);
                }}
              >
                Unlink wallet
              </button>
              <p className='muted'>
                This wallet is linked to your Sedition Account, which allows you to export your
                owned editions to the Ethereum blockchain as NFTs (non-fungible tokens) and to trade
                them on any NFT marketplace, such as OpenSea. Sedition is also scanning your wallet
                for any Sedition compatible artworks and will add them to your Vault automatically.
              </p>
            </>
          ) : null}
        </>
      )}

      <LinkWalletModal open={isLinkModalOpen} close={() => setLinkModalOpen(false)} />
      <UnlinkWalletModal open={isUnlinkModalOpen} close={() => setUnlinkModalOpen(false)} />
    </div>
  );
};
