import { ActionType } from 'typesafe-actions';

import { PlayerActionTypes, PlayerState } from './types';

export const INITIAL_STATE: PlayerState = {
  playlistId: undefined,
  playlistItemId: undefined,
};

// Actions & their type
import { Actions } from './actions';
export type ArtworksAction = ActionType<typeof Actions>;

export const playerReducer = (
  state = INITIAL_STATE,
  action: ArtworksAction,
): PlayerState => {
  switch (action.type) {
    case PlayerActionTypes.SET_PLAYLIST_DATA:
      return {
        ...state,
        playlistId: action.payload,
        playlistItemId: action.meta.playlistItemId,
      };

    default:
      return state;
  }
};
