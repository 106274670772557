import * as Types from 'types';

export enum PlayerActionTypes {
  SET_PLAYLIST_DATA = '@player/SET_PLAYLIST_DATA',
}

export interface PlayerState {
  readonly playlistId?: string;
  readonly playlistItemId?: string;
  readonly options?: Types.PlayerOptions;
}
