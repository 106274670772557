import React from 'react';
import classname from 'classnames';

import { Pagination } from 'components/Pagination';

import * as Types from 'types';

type Props<T> = {
  currentPage: number;
  items: T[];
  count: number;
  renderItem: (item: T, index: number) => React.ReactNode;
  onPageChange: (index: number) => void;
} & Types.Styleable;

export const PaginatedGrid = <T extends {}>({
  items,
  renderItem,
  onPageChange,
  className,
  style,
  currentPage,
  count,
}: Props<T>) => {
  return (
    <div className={classname('paginated-grid', className)} style={style}>
      <div className='paginated-grid__content row mb-1'>
        {items.map(renderItem)}
      </div>
      {count > 1 ? (
        <div className='paginated-grid__pagination text-right mb-3'>
          <Pagination
            currentPage={currentPage - 1}
            pageCount={count}
            onPageChange={onPageChange}
          />
        </div>
      ) : null}
    </div>
  );
};
