import React, { useState } from 'react';

type Props = {
  isOpenOnMount?: boolean;
  isClosable?: boolean;
  variant?: 'success' | 'error' | 'alert' | 'info' | 'warning';
  className?: string;
};

export const Alert: React.FC<Props> = ({
  isOpenOnMount = true,
  variant = 'info',
  className = '',
  children,
  isClosable = true,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenOnMount);

  return isOpen ? (
    <div className={`alert alert--${variant} ${className}`}>
      <div className='alert__content'>{children}</div>
      {isClosable && (
        <button
          className='alert__close icon-button icon-button--square icon-cancel pos-rel ml-1'
          onClick={() => setIsOpen(false)}
        />
      )}
    </div>
  ) : null;
};
