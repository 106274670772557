import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_PUBLISHABLE_KEY } from 'constants/global';
export const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

type Props = {
  clientSecret?: string;
  auctionMode?: boolean;
};

export const SeditionStripeElements: React.FC<Props> = ({ children, clientSecret, auctionMode }) => {
  if (!auctionMode && !clientSecret) {
    // Don't render children since they are dependant on <Elements />
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        ...(!auctionMode ? { clientSecret: clientSecret } : {}),
        appearance: {
          theme: 'flat',
          variables: {
            fontFamily: 'Din',
            borderRadius: '3px',
            colorPrimary: '#0088CC',
            colorBackground: '#F6F7F8',
            colorText: '#111116',
            colorDanger: '#FF3823',
            spacingUnit: '4px',
            fontWeightLight: '300',
            fontWeightNormal: 'normal',
            fontWeightMedium: '500',
            fontWeightBold: 'bold',
          },
        },
        fonts: [
          {
            family: 'Din',
            src: 'url(https://assets.seditionart.com/fonts/din/Din-Regular/DINWeb.woff)',
            weight: 'normal',
          },
        ],
      }}
    >
      {children}
    </Elements>
  );
};
