import React, { FC, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { usePaymentMethods } from 'features/stripe/hooks/usePaymentMethods';
import { useQueryParam } from 'hooks/useQueryParam';
import { AddCardButton } from 'components/payments/AddCardButton';

import { Title } from './Title';
import { CreditCardWithButtons } from './CreditCardWithButtons';
import { ConfirmCardRemovalModal } from './ConfirmCardRemovalModal';
import { AddPaymentMethodModal } from './AddPaymentMethodModal';

type Props = {
  userId: number;
};

export const PaymentMethodsManager: FC<Props> = ({ userId }) => {
  const [isAddPaymentMethodModalOpen, setIsAddPaymentMethodModalOpen] = useState(false);
  const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);
  const [paymentMethodToRemove, setPaymentMethodToRemove] = useState<undefined | string>();
  const {
    paymentMethodsState,
    paymentMethods,
    detachPaymentMethod,
    setDefaultPaymentMethod,
    fetchPaymentMethods,
  } = usePaymentMethods(userId);
  const redirectStatus = useQueryParam<'succeeded' | 'refreshing' | 'initial-fetch' | ''>(
    'redirect_status',
    '',
  );
  if (paymentMethodsState === 'error') {
    return (
      <p className='text-danger'>Unable to retrieve payment methods, please refresh the page</p>
    );
  }

  const columnClassName = 'col-12 col-sm-6 col-std-6 col-lg-4 mb-2';
  const showLoading =
    paymentMethodsState === 'initial-fetch' || paymentMethodsState === 'refreshing';
  const showSuccess = redirectStatus === 'succeeded' || paymentMethodAdded;

  return (
    <>
      <Title showSuccess={showSuccess} showLoading={showLoading} />
      <motion.div
        className='row mini-gutters-sm mb-3 position-relative'
        style={{ pointerEvents: showLoading ? 'none' : 'auto' }}
        animate={{ opacity: showLoading ? 0.9 : 1 }}
      >
        <AnimatePresence>
          {paymentMethods?.map((paymentMethod) => (
            <motion.div
              className={columnClassName}
              key={paymentMethod.id}
              exit={{ opacity: [null, 0.5, 0], y: [null, 0, -350] }}
              transition={{ ease: 'easeInOut', duration: 1, times: [0, 0.2, 1] }}
            >
              <CreditCardWithButtons
                paymentMethod={paymentMethod}
                onSetAsDefaultClick={setDefaultPaymentMethod}
                onRemoveClick={setPaymentMethodToRemove}
              />
            </motion.div>
          ))}
          {paymentMethodsState !== 'initial-fetch' && (
            <div className={columnClassName}>
              <AddCardButton
                onClick={() => {
                  setPaymentMethodAdded(false); // Clear previous success message
                  setIsAddPaymentMethodModalOpen(true);
                }}
              />
            </div>
          )}
        </AnimatePresence>
      </motion.div>
      <ConfirmCardRemovalModal
        paymentMethodToRemove={paymentMethodToRemove}
        setPaymentMethodToRemove={setPaymentMethodToRemove}
        onRemove={detachPaymentMethod}
      />
      <AddPaymentMethodModal
        isOpen={isAddPaymentMethodModalOpen}
        onRequestClose={() => setIsAddPaymentMethodModalOpen(false)}
        onPaymentAdded={() => {
          fetchPaymentMethods();
          setPaymentMethodAdded(true);
          setIsAddPaymentMethodModalOpen(false);
        }}
      />
    </>
  );
};
