import { request } from 'utils/request';

import * as Types from 'types';

export const requestPaymentMethods = async (userId: number): Promise<Types.PaymentMethod[]> => {
  const { data } = await request.get<Types.PaymentMethod[]>(
    `/api/internal/users/${userId}/stripe/payment_methods`,
    {
      // We always want to refetch payment methods.
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    },
  );
  return data;
};

export const deletePaymentMethod = async (paymentMethodIdOrLast4: string) => {
  return await request.delete(`/api/internal/stripe/payment_methods/${paymentMethodIdOrLast4}`);
};
