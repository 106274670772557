import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import { rootReducer } from 'store/rootReducer';

import * as Types from 'types';

import { mapPreloadedResponsesToStore } from './mapPreloadedResponsesToStore';

const preloadedStore: Partial<Types.RootState> = {
  ...mapPreloadedResponsesToStore(window.__preloadedResponses),
  currentUser: {
    user: sedition.loggedInUser,
    followedUsers: sedition.loggedInUser?.followedUsers || [],
    followedPlaylists: sedition.loggedInUser?.followedPlaylists || [],
  },
};

export const configureStore = () => {
  const composeEnhancers = composeWithDevTools({});
  return createStore(
    rootReducer,
    preloadedStore,
    composeEnhancers(applyMiddleware(thunkMiddleware)),
  );
};
