import React from 'react';

import { Spinner } from 'components/Spinner';
import { Alert } from 'components/UI/Alert';

type Props = {
  showLoading: boolean;
  showSuccess: boolean;
};

export const Title: React.FC<Props> = ({ showLoading, showSuccess }) => {
  return (
    <>
      {showSuccess && (
        <Alert variant='success' className='mb-2'>
          <span className='icon-checkmark mr-mini' />
          Payment card added
        </Alert>
      )}
      <div className='d-flex align-items-center mb-1'>
        <h1 className='headline headline--small mr-1'>Payment methods</h1>
        {showLoading && <Spinner size='xs' style={{ marginTop: -5 }} />}
      </div>
      <p className='muted mb-2'>
        Your payment information is securely stored in encrypted form on our payment processor
        (Stripe) servers. Stripe is a PCI Service Provider Level 1 which is the highest grade of
        payment processing security. Your chosen default payment method will be used for Art Stream
        payments (if you are subscribed) and in 1-click buy flows.
      </p>
    </>
  );
};
