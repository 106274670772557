import React, { createContext, useEffect, useState } from 'react';
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsName,
  useSigner,
  useSignMessage,
  useClient,
} from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

import { request } from 'utils/request';
import { generateMessageToSign } from 'services/nfts';
import { useCurrentUser } from 'hooks/useCurrentUser';

export const WagmiContext = createContext(undefined);

export const WagmiProvider = (props: any) => {
  const currentUser = useCurrentUser();
  const [verifiedAddress, setVerifiedAddress] = useState(currentUser?.walletAddress);
  const [message, _] = useState(generateMessageToSign());
  const [isSigning, setIsSigning] = useState(false);
  const [signError, setSignError] = useState<Error | undefined>();

  const {
    address: connectedAddress,
    isConnected,
    isConnecting,
    isDisconnected,
    connector,
  } = useAccount();
  const { data: ensName } = useEnsName({ address: connectedAddress });

  const { connect, connectors, error } = useConnect({
    connector: new InjectedConnector(),
    onSettled(_, error) {
      !error && linkAddress();
    },
  });

  const { disconnect, isLoading: isDisconnecting } = useDisconnect({
    onSuccess() {
      unlinkAddress();
    },
  });

  const { data: signer } = useSigner();

  const { data: signature, signMessage } = useSignMessage({
    message,
    onError(error) {
      setIsSigning(false);
      setSignError(error);
      disconnect();
    },
  });

  const linkAddress = async () => {
    if (!signer) {
      return;
    }

    try {
      setIsSigning(true);
      signMessage();
    } catch (e) {
      bugsnag.notify(e);
    }
  };

  const unlinkAddress = async () => {
    try {
      verifiedAddress &&
        (await request.delete(window.Routes.api_internal_wallet_path(verifiedAddress)));
    } catch (e) {
      bugsnag.notify(e);
    } finally {
      setVerifiedAddress(undefined);
    }
  };

  const verifyAddress = async () => {
    try {
      if (!signer) {
        throw new Error('Tried verifying address, but signer was not available');
      }
      const address = (await signer.getAddress()).toLowerCase();
      await request.post(window.Routes.api_internal_wallet_verify_path(address), {
        signature,
        message,
      });
      setVerifiedAddress(address);
    } catch (e) {
      console.error('error verifying address', e);
      bugsnag.notify(e);
      disconnect();
    } finally {
      setIsSigning(false);
    }
  };

  useEffect(() => {
    if (signature) {
      verifyAddress();
    }
  }, [signature]);

  /**
   * With useEffect when wallet is connected but not verified this would execute and show
   * a "Sign a message" dialog.
   */
  useEffect(() => {
    if (signer && !verifiedAddress) {
      linkAddress();
    }
  }, [signer, verifiedAddress]);

  return (
    <WagmiContext.Provider
      {...props}
      value={{
        verifiedAddress,
        ensName,
        signer: {
          ...signer,
          isSigning,
          signError,
        },
        connector: {
          availableConnectors: connectors,
          error,
          activeConnector: connector,
        },
        isConnecting,
        isConnected,
        isDisconnecting,
        isDisconnected,
        disconnect,
        connect,
      }}
    ></WagmiContext.Provider>
  );
};
