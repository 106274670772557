import React from 'react';
import classnames from 'classnames';

import { CreditCard } from 'components/payments/CreditCard';
import { MAX_WIDTH } from 'components/payments/CreditCardShape';

import * as Types from 'types';

type Props = {
  paymentMethod: Types.PaymentMethod;
  onRemoveClick: (paymentMethodId: string) => void;
  onSetAsDefaultClick: (paymentMethodId: string) => void;
};

export const CreditCardWithButtons: React.FC<Props> = ({
  paymentMethod,
  onRemoveClick,
  onSetAsDefaultClick,
}) => {
  return (
    <>
      <CreditCard cc={paymentMethod.card} className='mb-mini' />
      <div className='text-center' style={{ maxWidth: MAX_WIDTH }}>
        <button
          type='button'
          onClick={() => onRemoveClick(paymentMethod.id)}
          className='btn btn-mini'
        >
          Remove
        </button>
        <button
          type='button'
          onClick={() => onSetAsDefaultClick(paymentMethod.id)}
          className={classnames('btn btn-mini', {
            'btn--disabled btn--blended': paymentMethod.default,
          })}
        >
          {paymentMethod.default ? (
            <span>
              <span className='icon-checkmark pr-mini' style={{ fontSize: 11 }} />
              Default
            </span>
          ) : (
            'Set as default'
          )}
        </button>
      </div>
    </>
  );
};
