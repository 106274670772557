import React, { FC } from 'react';
import moment from 'moment';
import { sprintf } from 'sprintf';

import * as Types from 'types';
import { Avatar } from 'components/users/Avatar';

type Props = {
  activity: Types.Action;
};

export const ArtworkActivity: FC<Props> = ({ activity: { body, timestamp, userCard } }) => {
  const momentInstance = moment(timestamp);

  return (
    <a
      className='action-entity'
      title={sprintf('View %s profile', userCard.name)}
      href={userCard.url}
    >
      <div className='row no-gutters align-items-center'>
        <div className='col-auto mr-mini'>
          <Avatar
            name={userCard.name}
            avatarUrl={userCard.avatarUrl}
            size='smaller'
            className='d-block'
          />
        </div>
        <div className='col'>
          <div className='action-entity__username'>{userCard.name}</div>
          <div className='action-entity__body'>{body}</div>
          <div className='action-entity__timestamp'>
            <time
              className='js-timeago'
              dateTime={momentInstance.fromNow()}
              title={momentInstance.toString()}
            >
              {momentInstance.fromNow()}
            </time>
          </div>
        </div>
      </div>
    </a>
  );
};
