import { combineReducers } from 'redux';
import { editionsReducer } from 'features/editions/reducer';
import { playlistsReducer } from 'features/playlists/reducer';
import { artworksReducer } from 'features/artworks/reducer';
import { artworkGroupsReducer } from 'features/artwork_groups/reducer';
import { currentUserReducer } from 'features/currentUser/reducer';
import { modalsReducer } from 'features/modals/reducer';
import { playerReducer } from 'features/player/reducer';
import { mediaReducer } from 'features/media/reducer';
import { usersReducer } from 'features/users/reducer';
import { listsReducer } from 'features/lists/reducer';

export const rootReducer = combineReducers({
  lists: listsReducer,
  artworks: artworksReducer,
  artworkGroups: artworkGroupsReducer,
  editions: editionsReducer,
  playlists: playlistsReducer,
  currentUser: currentUserReducer,
  modals: modalsReducer,
  player: playerReducer,
  media: mediaReducer,
  users: usersReducer,
});
