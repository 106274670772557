import React, { FC } from 'react';

import { getShortenedAddress, etherscanUrlForAddress } from 'services/nfts';

type Props = {
  address: string;
  name?: string;
  icon?: 'wallet' | 'logo';
};

export const WalletCard: FC<Props> = ({ name, address, icon = 'wallet' }) => {
  const shortAddress = getShortenedAddress(address);

  return (
    <div className='d-flex artwork-header--sm'>
      <div
        className={`wallet-card__icon wallet-card__icon--${icon} d-flex justify-content-center align-items-center mr-mini`}
      >
        <span className={`icon-${icon} d-flex justify-content-center align-items-center`} />
      </div>
      <div className='d-flex flex-column justify-content-center'>
        {name && <div className='artwork-header__name lh-1'>{name}</div>}
        <a
          className='wallet-card__address'
          target='_blank'
          rel='noopener noreferrer nofollow'
          title={address}
          href={etherscanUrlForAddress(address)}
        >
          {shortAddress}
        </a>
      </div>
    </div>
  );
};
