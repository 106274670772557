import { ActionType } from 'typesafe-actions';

import { arrayToById } from 'services/arrayToById';

import { UsersActionTypes, UsersState } from './types';

export const INITIAL_STATE: UsersState = {
  byId: {},
};

// Actions & their type
import { Actions } from './actions';
export type UsersAction = ActionType<typeof Actions>;

export const usersReducer = (
  state = INITIAL_STATE,
  action: UsersAction,
): UsersState => {
  switch (action.type) {
    case UsersActionTypes.RECEIVE_USERS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...arrayToById(action.payload),
        },
      };

    default:
      return state;
  }
};
