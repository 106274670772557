import * as Types from 'types';

export enum EditionsActionTypes {
  REQUEST_EDITIONS = '@editions/REQUEST_EDITIONS',
  RESET_EDITIONS = '@editions/RESET_EDITIONS',
  SET_EDITIONS = '@editions/SET_EDITIONS',
  SHOW_GIFT_EDITION = '@editions/SHOW_GIFT_EDITION',
}

export interface EditionsState {
  readonly byId: {
    [id: number]: Types.Edition;
  };
  readonly isFetching: boolean;
  readonly isInitial: boolean;
  readonly giftEditionId?: number;
}
