import queryString from 'query-string';

import { createPropsToQueryParamsMapper } from 'utils/createPropsToQueryParamsMapper';
import * as Types from 'types';

import { BrowseFilters, ARTWORK_LIST_DEFAULT_PRICE_RANGE } from './types';
import { updateParam } from 'apps/browse/utils/updateParam';

export const PER_PAGE = process.env.NODE_ENV === 'development' ? 9 : 42;

type MapperType = {
  type: Types.ArtistTypes;
  medium: BrowseFilters.MediumTypes;
  price: [number, number];
  quality: BrowseFilters.QualityTypes;
  order: BrowseFilters.OrderTypes;
  tags: string[];
  nft: 'true' | null;
  orientation: BrowseFilters.OrientationTypes;
};

export const mapper = createPropsToQueryParamsMapper<MapperType>(
  {
    type: (value, queryParams) => {
      queryParams['filter'] = updateParam(
        queryParams['filter'],
        Object.keys(BrowseFilters.LABELED_ARTIST_TYPES),
        [value],
      );
      return queryParams;
    },
    medium: (value, queryParams) => {
      queryParams['filter'] = updateParam(
        queryParams['filter'],
        Object.keys(BrowseFilters.LABELED_MEDIUM_TYPES),
        value === 'any' ? [] : [value],
      );
      return queryParams;
    },
    price: (value, queryParams) => {
      queryParams['filter'] = updateParam(queryParams['filter'], [], ['price']);
      if (ARTWORK_LIST_DEFAULT_PRICE_RANGE[1] === value[1]) {
        // In upper limit equals to max price let's only use
        // lower limit for filtering. aka infinity filter 0$ to $500+
        queryParams['price'] = String(value[0]);
      } else {
        queryParams['price'] = value.join('-');
      }
      return queryParams;
    },
    quality: (value, queryParams) => {
      if (value === 'any') {
        delete queryParams['quality'];
        queryParams['filter'] = updateParam(queryParams['filter'], ['quality'], []);
      } else {
        queryParams['filter'] = updateParam(queryParams['filter'], [], ['quality']);
        queryParams['quality'] = value;
      }
      return queryParams;
    },
    order: (value, queryParams) => {
      switch (value) {
        case 'alphabetical':
          queryParams['order'] = 'alphabetical';
          delete queryParams['direction'];
          break;

        case 'last_remaining':
          queryParams['order'] = 'last_remaining';
          queryParams['direction'] = 'desc';
          break;
        case 'price':
          queryParams['order'] = 'price';
          queryParams['direction'] = 'desc';
          break;
        case 'popular':
          queryParams['order'] = 'popular';
          queryParams['direction'] = 'desc';
          break;
        case 'date':
        default:
          queryParams['order'] = 'date';
          queryParams['direction'] = 'desc';
          break;
      }
      return queryParams;
    },
    tags: (value, queryParams) => {
      queryParams['filter'] = updateParam(
        queryParams['filter'],
        value.length ? [] : ['tags'],
        value.length ? ['tags'] : [],
      );

      queryParams['tags'] = value.length ? value.join(',') : undefined;

      return queryParams;
    },
    nft: (value, queryParams) => {
      queryParams['filter'] = updateParam(
        queryParams['filter'],
        value ? [] : ['nft'],
        value ? ['nft'] : [],
      );
      queryParams['nft'] = value === 'true' ? value : null;

      return queryParams;
    },
    orientation: (value, queryParams) => {
      if (value === 'any') {
        delete queryParams['orientation'];
        queryParams['filter'] = updateParam(queryParams['filter'], ['orientation'], []);
      } else {
        queryParams['filter'] = updateParam(queryParams['filter'], [], ['orientation']);
        queryParams['orientation'] = value;
      }
      return queryParams;
    },
  },
  (params: any) => {
    if (typeof params['filter'] === 'string') {
      params['filter'] = params['filter'].split(',');
    }
    return params;
  },
  (params: any) => {
    if (Array.isArray(params['filter'])) {
      params['filter'] = params['filter'].join(',');
    }
    return params;
  },
);

export const getPriceQueryParam = (defaultValue: [number, number]): [number, number] => {
  const queryParams = queryString.parse(document.location.search);
  const value = queryParams['price'];
  if (typeof value === 'string') {
    const parts = value.split('-');
    return [parseInt(parts[0], 10), parseInt(parts[1], 10)];
  } else {
    return defaultValue;
  }
};
