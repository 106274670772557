import React, { FC } from 'react';

import * as Types from 'types';

import { Artwork, CLASS_NAME } from 'components/artworks/Artwork';
import { ArtworkPlaceholder } from 'components/artworks/ArtworkPlaceholder';

import { useArtworkLoader } from 'features/artworks/hooks/useArtworkLoader';

type Props = {
  artworkId?: number;
  cacheKey?: string;
  onPlay?: (artworkId: number) => void;
} & Types.Styleable;

export const AtomicArtwork: FC<Props> = ({
  artworkId,
  cacheKey,
  className,
  style,
  onPlay,
  children,
}) => {
  const artwork = useArtworkLoader(artworkId, cacheKey);

  return artwork ? (
    <Artwork
      className={className}
      style={style}
      artwork={artwork}
      onPlay={onPlay}
      playUI='thumbnail'
      small
      showPrice
    >
      {children}
    </Artwork>
  ) : (
    <ArtworkPlaceholder id={artworkId} className={[CLASS_NAME, className].join(' ')} />
  );
};
