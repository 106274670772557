import React from 'react';
import { Portal } from 'react-portal';

// Renders output only if element exists.
// Useful because Portal will render at end of <body />
// when element does not exist.
export const ConditionalPortal: React.FC<{ selector: string }> = ({
  selector,
  children,
}) => {
  const element = $(selector)[0];

  if (element) {
    return <Portal node={element}>{children}</Portal>;
  } else {
    return null;
  }
};
