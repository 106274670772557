import { createClient, configureChains } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { ALCHEMY_API_KEY } from 'constants/global';

import { CHAIN } from 'services/nfts';

export const _configureWagmi = () => {
  const { chains, provider } = configureChains(
    [CHAIN],
    [alchemyProvider({ alchemyId: ALCHEMY_API_KEY })],
  );

  return createClient({
    autoConnect: true,
    provider,
    connectors: [
      new MetaMaskConnector({ chains }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: 'seditionart',
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
        },
      }),
    ],
  });
};

export const configureWagmi = () => {
  if (window.wagmiClient) {
    return window.wagmiClient;
  }

  window.wagmiClient = _configureWagmi();
  return window.wagmiClient;
};
