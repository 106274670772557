import queryString, { ParsedQuery } from 'query-string';

/**
 * Utility to create a standard mapping between Hash and URL
 * Query Params, for example:
 *
 * const mapper = createPropsToQueryParamsMapper({
 *   someKey: (value, params) => {
 *     params['someOtherKey'] = value.toUpperCase();
 *     return params;
 *   }
 * });
 *
 * mapper('/artworks?foo=bar', { one: 1, someKey: 'two' })
 *   // => '/artworks?foo=bar&one=1&someOtherKey=TWO'
 *
 * @param rules
 */
export const createPropsToQueryParamsMapper = <T extends Record<string, any>>(
  rules: {
    [id in keyof T]: (value: T[id], queryParams: ParsedQuery) => ParsedQuery;
  },
  beforeFilter?: (params: ParsedQuery) => ParsedQuery,
  afterFilter?: (params: ParsedQuery) => ParsedQuery,
) => {
  return <X extends keyof T, Y extends T[X]>(
    url: string,
    params: Record<X, Y>,
  ) => {
    const parsed = queryString.parseUrl(url);
    let query = beforeFilter ? beforeFilter(parsed.query) : parsed.query;

    for (const key in params) {
      if (key in rules) {
        query = rules[key as keyof T]((params as T)[key as keyof T], query);
      } else {
        query[key] = params[key];
      }
    }

    query = afterFilter ? afterFilter(query) : query;
    return parsed.url + '?' + queryString.stringify(query);
  };
};
