import React, { CSSProperties } from 'react';

import { AspectRatio } from 'components/UI/AspectRatio';

type Props = {
  style?: CSSProperties;
  className?: string;
};

export const MAX_WIDTH = 380;
export const MIN_WIDTH = 288;

export const CreditCardShape: React.FC<Props> = ({ children, style, className }) => {
  return (
    <AspectRatio
      ratio='credit-card'
      style={{
        maxWidth: MAX_WIDTH,
        minWidth: MIN_WIDTH,
        overflow: 'hidden',
        borderRadius: 6,
        ...style,
      }}
      className={className}
    >
      {children}
    </AspectRatio>
  );
};
