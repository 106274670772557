import React, { FC, useEffect, useState } from 'react';

import { GenericSlate } from 'components/Slate/GenericSlate';

import * as Types from 'types';
import { AtomicArtwork } from 'components/artworks/AtomicArtwork';
import { requestActions, ReceiverTypes } from 'features/actions/api';

import { ArtworkActivity } from './components/ArtworkActivity';

type Props = {
  getActivity: () => Promise<Types.Action[]>;
  title?: string;
};

export const ActivitySlate: FC<Props> = ({ getActivity, title = 'Recent Activity' }) => {
  const [activities, setActivities] = useState<Types.Action[]>();

  useEffect(() => {
    (async function () {
      const activity = await getActivity();

      setActivities(activity);
    })();
  }, []);

  return (
    <GenericSlate<Types.Action>
      title={title}
      pageSize={3}
      items={activities ?? []}
      renderComponent={(activity) => (
        <AtomicArtwork artworkId={activity.artworkId}>
          <ArtworkActivity activity={activity} />
        </AtomicArtwork>
      )}
    />
  );
};

export const TradeActivitySlate: FC = ({}) => {
  return (
    <ActivitySlate
      title='Recent trade activity'
      getActivity={() => requestActions({ type: 'trade' })}
    />
  );
};

export const ProfileActivitySlate: FC<{ userId: number; artistId?: number }> = ({
  userId,
  artistId,
}) => {
  const params: { receiver: ReceiverTypes; receiverId: number } = artistId
    ? { receiver: 'receiver_artist', receiverId: artistId }
    : { receiver: 'receiver_user', receiverId: userId };

  return <ActivitySlate getActivity={() => requestActions({ type: 'profile', ...params })} />;
};

export const ArtworkActivitySlate: FC<{ artworkId: number }> = ({ artworkId }) => {
  const params: { receiver: ReceiverTypes; receiverId: number } = {
    receiver: 'receiver_artwork',
    receiverId: artworkId,
  };

  return <ActivitySlate getActivity={() => requestActions({ type: 'profile', ...params })} />;
};
