import React from 'react';

import { ConfirmationModal } from 'components/modal';

type Props = {
  paymentMethodToRemove?: string;
  setPaymentMethodToRemove: (paymentMethodId?: string) => void;
  onRemove: (paymentMethodId: string) => void;
};

export const ConfirmCardRemovalModal: React.FC<Props> = ({
  paymentMethodToRemove,
  setPaymentMethodToRemove,
  onRemove,
}) => {
  return (
    <ConfirmationModal
      title='Confirm'
      onConfirm={() => {
        if (paymentMethodToRemove) {
          onRemove(paymentMethodToRemove);
        }
        setPaymentMethodToRemove(undefined);
      }}
      onRequestClose={() => setPaymentMethodToRemove(undefined)}
      dangerLabel='Remove'
      cancel={true}
      isOpen={paymentMethodToRemove !== undefined}
    >
      'Are you sure you want to remove this payment method?'
    </ConfirmationModal>
  );
};
