export enum ModalActionTypes {
  SHOW_ERROR_MODAL = '@modals/SHOW_ERROR_MODAL',
  HIDE_ERROR_MODAL = '@modals/HIDE_ERROR_MODAL',
}

export interface ModalState {
  readonly errorModalActive: boolean;
  readonly errorModalMessage?: string;
  readonly urlToGoAfterModalClose?: string;
}
