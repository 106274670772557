export enum PAGE_TYPES {
  PURCHASED = 'purchased',
  WISHLIST = 'wishlist',
}

export const pageTypeToAPIQuery = (userId: number, pageType?: string) => {
  switch (pageType) {
    case PAGE_TYPES.PURCHASED:
      return {
        filter: 'purchased_by',
        purchased_by: String(userId),
      };
    case PAGE_TYPES.WISHLIST:
      return {
        filter: 'user_wishlist',
        user_wishlist: String(userId),
      };
    default:
      return {
        filter: 'artist_user',
        artist_user: String(userId),
      };
  }
};
