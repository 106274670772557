import React from 'react';

import { FollowPlaylistButton } from 'components/playlists/FollowPlaylistButton';
import { AtomicArtwork } from 'components/artworks/AtomicArtwork';
import { SvgIcon } from 'components/icons/SvgIcon';

import * as Types from 'types';

type Props = {
  playlist: Types.Playlist;
  className?: string;
};

export const PlaylistSlate: React.FC<Props> = ({ playlist, className }) => {
  if (playlist.items.length === 0) {
    return null;
  }

  return (
    <article className={className}>
      <div className='row align-items-top mb-1'>
        <div className='col-auto'>
          <h1
            className='headline headline--medium headline--subtle headline--readable mb-mini'
            style={{ lineHeight: 1 }}
          >
            {playlist.name}
          </h1>
          <div className='pos-rel'>
            <div className='key-value key-value__value' style={{ paddingLeft: '24px' }}>
              <SvgIcon
                style={{ top: 0, left: 0, fontSize: '16px', lineHeight: '12px' }}
                icon='waves'
                className='pos-abs'
              />
              Curated playlist
            </div>
          </div>
        </div>
        <div className='col-auto'>
          <FollowPlaylistButton
            className='btn btn--bg-gray btn--medium'
            playlistId={parseInt(playlist.id, 10)}
            iconStyle={{ left: 11, marginTop: 1 }}
            style={{ marginTop: -4 }}
          />
        </div>
      </div>

      {playlist.description ? (
        <p
          className='standfirst standfirst--subtle nmt-mini mb-1 lh-tight'
          style={{ maxWidth: 600 }}
        >
          {playlist.description}
        </p>
      ) : null}
      <div className='row'>
        {playlist.items.map((item, index) => {
          return (
            <div className='col-12 col-sm-6 col-lg-3' key={`${index}-${item.id}`}>
              <AtomicArtwork artworkId={item.artwork.id} cacheKey='playlist-slate' />
            </div>
          );
        })}
      </div>
    </article>
  );
};
