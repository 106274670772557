import React, { FC } from 'react';
import { sprintf } from 'sprintf';

import { useWagmi } from 'hooks/useWagmi';
import { ConfirmationModal } from 'components/modal';

type Props = {
  close: () => void;
  open: boolean;
};

export const UnlinkWalletModal: FC<Props> = ({ open, close }) => {
  const { disconnect, isDisconnecting } = useWagmi();

  const unlinkWallet = () => {
    try {
      disconnect();
    } catch (e) {
      bugsnag.notify(e);
    } finally {
      close();
    }
  };

  return (
    <ConfirmationModal
      isOpen={open}
      title='Unlink Wallet'
      dangerLabel='Unlink'
      onConfirm={unlinkWallet}
      onRequestClose={close}
      isLoading={isDisconnecting}
      dark
      cancel
    >
      <div className='d-flex flex-column'>
        <p>
          If you unlink your Ethereum wallet, any NFT editions that belong to your Wallet address
          will disappear from your Sedition Vault. They will appear again if the wallet is relinked.
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html: sprintf(
              'For more information about NFTs, Ethereum and wallets, please visit our %sLearn More%s section.',
              `<a href='${'https://www.seditionart.com/help-centre/nfts/beginner-s-nft-guide'}' class='link-border'>`,

              '</a>',
            ),
          }}
        ></p>
      </div>
    </ConfirmationModal>
  );
};
