import * as Types from 'types';

export enum ArtworksActionTypes {
  FETCH_ARTWORK = '@artworks/FETCH_ARTWORK',
  RECEIVE_ARTWORK = '@artworks/RECEIVE_ARTWORK',
  RECEIVE_ARTWORKS = '@artworks/RECEIVE_ARTWORKS',
  SET_ACTIVE_ARTWORK = '@artworks/SET_ACTIVE_ARTWORK',
}

export interface ArtworksState {
  readonly byId: {
    [id: string]: Types.Artwork | Types.ArtworkDetails;
  };
  readonly activeArtworkId?: number;
}
