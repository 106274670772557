import React, { CSSProperties, FC } from 'react';
import { capitalize } from 'lodash';

import * as Types from 'types';

import { ConditionalAnchor } from 'components/ConditionalAnchor';
import { CreditCardShape } from 'components/payments/CreditCardShape';
import { emailValidator } from 'utils/validators';
import { Visa } from 'components/icons/Visa';
import { Mastercard } from 'components/icons/Mastercard';
import { AmericanExpress } from 'components/icons/AmericanExpress';

type Props = {
  cc: Types.CreditCard;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
  isSelected?: boolean;
};

const BrandIcon: FC<{ brand: string }> = ({ brand }) => {
  switch (brand) {
    case 'amex':
      return <AmericanExpress width={24} />;
    case 'mastercard':
      return <Mastercard width={30} />;
    case 'visa':
      return <Visa width={48} />;
    default:
      return <span>{capitalize(brand)}</span>;
  }
};

export const CreditCard: React.FC<Props> = ({ cc, className, style, isSelected, onClick }) => {
  const isNamePresent = cc.name && cc.name.length > 0 && !emailValidator(cc.name);
  const variation = `${cc.last4}`[3];

  return (
    <CreditCardShape className={className} style={style}>
      <ConditionalAnchor
        className={`credit-card credit-card--gradient-${variation} ${
          isSelected ? 'credit-card--selected' : ''
        }`}
        onClick={onClick}
        shouldWrap={onClick !== undefined}
      >
        <div style={{ minHeight: 40 }} />
        <div className='flex-grow-1 d-flex flex-column justify-content-center'>
          <div className='credit-card__number'>
            ****{'\u00A0'}****{'\u00A0'}****{'\u00A0'}
            {cc.last4}
          </div>
        </div>
        <div>{isNamePresent ? `${cc.expiresMonth} / ${cc.expiresYear}` : '\u00A0'}</div>
        <div className='row mb-mini align-items-end'>
          <div className='col'>
            {isNamePresent ? cc.name : `${cc.expiresMonth} / ${cc.expiresYear}`}
          </div>
          <div className='col-auto'>
            <BrandIcon brand={cc.brand} />
          </div>
        </div>
      </ConditionalAnchor>
    </CreditCardShape>
  );
};
