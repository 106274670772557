import { action } from 'typesafe-actions';

import * as Types from 'types';

import { ArtworksActionTypes } from './types';
import {
  requestArtworkBySlug,
  requestArtworks,
  RequestArtworksProps,
} from './api';
import { THE_CRÈME_DE_LA_CRÈME_LIST_ID } from './constants';
import { fetchNextPage } from 'features/lists/actions';

// TODO: API changed
export const startArtworkFetch = (artworkId: number) =>
  action(ArtworksActionTypes.FETCH_ARTWORK, artworkId);

export const receiveArtwork = (artwork: Types.Artwork | Types.ArtworkDetails) =>
  action(ArtworksActionTypes.RECEIVE_ARTWORK, artwork);

export const receiveArtworks = (artworks: Types.Artwork[]) =>
  action(ArtworksActionTypes.RECEIVE_ARTWORKS, artworks);

export const setActiveArtworkId = (activeArtworkId: number) =>
  action(ArtworksActionTypes.SET_ACTIVE_ARTWORK, activeArtworkId);

export const Actions = {
  startArtworkFetch,
  receiveArtwork,
  receiveArtworks,
  setActiveArtworkId,
};

export const fetchArtworkDetailsBySlug =
  (slug: string): Types.DefaultThunkAction<Promise<Types.Artwork>> =>
  async (dispatch) => {
    const response = await requestArtworkBySlug(slug);
    dispatch(receiveArtwork(response));
    return response;
  };

export const fetchArtworksById =
  (ids: number[]): Types.DefaultThunkAction =>
  (dispatch) => {
    const queryParams: RequestArtworksProps = {
      filter: ['ids'],
      ids,
      perPage: 20,
      order: 'date',
      direction: 'desc',
    };

    requestArtworks(queryParams).then((artworks) => {
      dispatch(receiveArtworks(artworks));
      return artworks;
    });
  };

export const fetchArtworksByArtistId =
  (artistUserId: number): Types.DispatchThunkAction<Promise<Types.Artwork[]>> =>
  async (dispatch) => {
    const queryParams: RequestArtworksProps = {
      filter: ['artist_user'],
      artistUser: artistUserId,
      perPage: 100,
      order: 'date',
      direction: 'desc',
    };

    const artworks = await requestArtworks(queryParams);
    dispatch(receiveArtworks(artworks));
    return artworks;
  };

export const fetchCrèmeDeLaCrèmeArtworksList =
  (): Types.DefaultThunkAction => (dispatch) => {
    let listId = THE_CRÈME_DE_LA_CRÈME_LIST_ID;

    const queryParams: RequestArtworksProps = {
      filter: 'available',
      perPage: 20,
      order: 'popular',
      direction: 'desc',
    };

    dispatch(
      fetchNextPage(
        listId,
        () =>
          requestArtworks(queryParams).then((artworks) => {
            dispatch(receiveArtworks(artworks));
            return artworks;
          }),
        { refresh: true },
      ),
    );
  };
