import React from 'react';

interface BaseProps {
  className?: string;
}

interface PropsWithWidth extends BaseProps {
  width: number;
  height?: never;
}

interface PropsWithHeight extends BaseProps {
  width?: never;
  height: number;
}

type Props = PropsWithWidth | PropsWithHeight;

const ratio = 256 / 160;

export const Mastercard: React.FC<Props> = ({ width, height, className }) => {
  const size = {
    width: width ? width : Math.round((height as number) * ratio),
    height: height ? height : Math.round((width as number) / ratio),
  };

  return (
    <svg className={className} width={size.width} height={size.height} viewBox='0 0 256 160'>
      <g fillRule='nonzero' fill='none'>
        <path
          d='M255.1 79.8c0 44-35.3 79.7-78.9 79.7a79.3 79.3 0 0 1-78.8-79.7c0-44 35.3-79.8 78.8-79.8a79.3 79.3 0 0 1 79 79.8z'
          fill='#F79F1A'
        />
        <path
          d='M157.8 79.8c0 44-35.3 79.7-78.9 79.7A79.3 79.3 0 0 1 .1 79.8C0 35.8 35.4 0 78.9 0a79.3 79.3 0 0 1 79 79.8z'
          fill='#EA001B'
        />
        <path
          d='M127.6 17a79.9 79.9 0 0 0-30.2 62.7 80 80 0 0 0 30.2 62.8 80 80 0 0 0 30.2-62.8A79.9 79.9 0 0 0 127.6 17z'
          fill='#FF5F01'
        />
      </g>
    </svg>
  );
};
