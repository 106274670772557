import React from 'react';

import { Modal } from 'components/modal';
import { HOST } from 'constants/global';
import { AddPaymentMethodForm } from 'components/payments/AddPaymentMethodForm';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onPaymentAdded: () => void;
};

export const AddPaymentMethodModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  onPaymentAdded,
}) => {
  return (
    <Modal
      title='Add payment method'
      styleContent={{ position: 'relative', minHeight: 320 }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <AddPaymentMethodForm
        returnUrl={HOST + Routes.payment_methods_account_user_path()}
        onPaymentAdded={onPaymentAdded}
      />
    </Modal>
  );
};
