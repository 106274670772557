import React, { FC, useEffect } from 'react';
import { Connector } from 'wagmi';

import { useWagmi } from 'hooks/useWagmi';

import { assetUrl } from 'constants/global';
import { ConfirmationModal } from 'components/modal';
import { Alert } from 'components/UI/Alert';
import { sprintf } from 'sprintf';

type Props = {
  open: boolean;
  close: () => void;
};

const descriptiveError = (error?: Error) => {
  // No error when user rejects
  if (!error || error.name === 'UserRejectedRequestError') {
    return undefined;
  }

  if (error.message === 'Resource unavailable') {
    return 'Wallet is locked, please unlock your wallet and try again.';
  } else {
    return error.message;
  }
};

export const LinkWalletModal: FC<Props> = ({ open, close }) => {
  const {
    connector: { availableConnectors, error, activeConnector },
    connect,
    isConnected,
    verifiedAddress,
    signer: { isSigning, signError },
  } = useWagmi();

  const linkWallet = (connector: Connector) => {
    try {
      connect(connector);
    } catch (e) {
      bugsnag.notify(e);
    }
  };

  const errorMessage = descriptiveError(error || signError);

  useEffect(() => {
    if (isConnected && verifiedAddress) {
      setTimeout(close, 1000);
    }
  }, [isConnected, verifiedAddress]);

  return (
    <ConfirmationModal
      confirm={false}
      cancel={true}
      cancelLabel={!isSigning && activeConnector?.id ? 'Close' : 'Cancel'}
      isOpen={open}
      title={isSigning ? 'Please sign a message' : 'Choose your wallet app'}
      onRequestClose={close}
      dark
    >
      <div className='d-flex flex-column'>
        {isSigning ? (
          <p>
            Follow the instructions in your wallet to sign a message. Signing a message is free and
            only allows us to verify that you are the owner of the Wallet.
          </p>
        ) : (
          <>
            <p>Select which wallet you'd like to link</p>
            <ul className='ml-0 mb-0' style={{ borderBottom: '1px solid rgba(53, 54, 70, 1)' }}>
              {availableConnectors.map((connector) => (
                <li
                  className='d-flex align-items-center'
                  style={{ borderTop: '1px solid rgba(53, 54, 70, 1)' }}
                  key={connector.id}
                >
                  <button
                    className='btn btn--text btn--wallet-provider px-1 d-flex align-items-end'
                    disabled={!connector.ready}
                    key={connector.id}
                    onClick={() => linkWallet(connector)}
                  >
                    <img
                      src={assetUrl(`wallet/${connector.id.toLowerCase()}.webp`)}
                      alt={connector.name}
                      width={22}
                      className='mr-1'
                    />
                    {connector.ready ? connector.name : sprintf('%s not ready', connector.name)}
                  </button>
                  {activeConnector?.id === connector.id && verifiedAddress && (
                    <span className='icon icon-checkmark ml-auto color-ok' />
                  )}
                </li>
              ))}
            </ul>
            {errorMessage && (
              <Alert className='mt-1' variant='error'>
                {errorMessage}
              </Alert>
            )}
          </>
        )}
      </div>
    </ConfirmationModal>
  );
};
