import { useContext } from 'react';

import { WagmiContext } from 'context/WagmiContext';
import { WagmiContextValue } from 'context/WagmiContext/types';

export const useWagmi = (): WagmiContextValue => {
  const context = useContext(WagmiContext);
  if (context === undefined) {
    throw new TypeError('useWagmi must be used within a WagmiProvider');
  }
  return context;
};
