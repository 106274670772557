import React from 'react';
import ReactPaginate from 'react-paginate';

const PER_PAGE = 60;

export type Props = {
  currentPage: number;
  pageCount: number;
  onPageChange: (index: number) => void;
};

export const Pagination: React.FC<Props> = ({
  pageCount,
  onPageChange,
  currentPage,
}) => {
  return (
    <ReactPaginate
      containerClassName='pagination'
      pageCount={pageCount}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      forcePage={currentPage}
      disableInitialCallback={true}
      onPageChange={(selectedItem) => onPageChange(selectedItem.selected)}
    />
  );
};
