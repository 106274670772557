import React, { CSSProperties } from 'react';

import { CreditCardShape } from 'components/payments/CreditCardShape';

type Props = {
  onClick: () => void;
  style?: CSSProperties;
};

export const AddCardButton: React.FC<Props> = ({ onClick, style }) => {
  return (
    <CreditCardShape>
      <a
        onClick={onClick}
        className='credit-card credit-card--blank align-items-center js-add-credit-card'
        style={{ justifyContent: 'center', width: '100%' }}
      >
        <span className='icon-circled-plus' style={{ fontSize: 32 }} />
        <span className='fw-bold'>Add Credit Card</span>
      </a>
    </CreditCardShape>
  );
};
