import { ActionType } from 'typesafe-actions';

import { ArtworkGroupsState, ArtworkGroupsActionTypes } from './types';

export const INITIAL_STATE: ArtworkGroupsState = {
  bySlug: {},
};

// Actions & their type
import { Actions } from './actions';
export type ArtworksAction = ActionType<typeof Actions>;

export const artworkGroupsReducer = (
  state = INITIAL_STATE,
  action: ArtworksAction,
): ArtworkGroupsState => {
  switch (action.type) {
    case ArtworkGroupsActionTypes.RECEIVE_ARTWORK_GROUP:
      return {
        ...state,
        bySlug: {
          ...state.bySlug,
          [action.payload.slug]: action.payload,
        },
      };

    default:
      return state;
  }
};
