import React, { FC } from 'react';

import { ConditionalPortal } from 'artworks/ArtworkDetail/components/ConditionalPortal';
import { LinkWalletContainer } from 'components/LinkWallet/LinkWalletContainer';
import { WagmiProvider } from 'context/WagmiContext';

type Props = {};

export const ConnectWallet: FC<Props> = ({}) => {
  return (
    <WagmiProvider>
      <ConditionalPortal selector='.js-connect-wallet'>
        <form>
          <div className='settings-subsection'>
            <h1>Ethereum Wallet</h1>
            <LinkWalletContainer showDetails />
          </div>
        </form>
      </ConditionalPortal>
    </WagmiProvider>
  );
};
