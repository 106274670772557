import * as Types from 'types';

import { arrayToById } from 'services/arrayToById';
import { arrayToBySlug } from 'services/arrayToBySlug';

import { mapResponseArtworkDetailsToArtworkDetails } from 'features/artworks/api';
import { mapResponseUserToUser } from 'features/users/api';
import { mapResponseArtworkGroupToArtworkGroup } from 'features/artwork_groups/api';

export const mapPreloadedResponsesToStore = (
  data: typeof window.__preloadedResponses,
): Partial<Types.RootState> => {
  return {
    artworks: {
      byId: arrayToById(
        data?.['ResponseArtworkDetails']?.map(
          mapResponseArtworkDetailsToArtworkDetails,
        ) || [],
      ),
    },
    users: {
      byId: arrayToById(
        data?.['ResponseUser']?.map(mapResponseUserToUser) || [],
      ),
    },
    artworkGroups: {
      bySlug: arrayToBySlug(
        data?.['ResponseArtworkGroup']?.map(
          mapResponseArtworkGroupToArtworkGroup,
        ) || [],
      ),
    },
  };
};
