import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { StyledCheckbox } from 'components/UI/StyledCheckbox';
import { useBroadcast } from 'hooks/useBroadcast';

import { ContentAnimator } from './ContentAnimator';
import { defaultErrorMessage } from './helpers';

type Props = {
  /**
   * In case Stripe has to redirect provide a URL which it should return user to.
   */
  returnUrl: string;
  /**
   * Label of CTA button. Default is 'Continue'.
   */
  ctaLabel?: string;
  onUiStateChange: (uiState: UiStates) => void;
  onSuccess: (paymentMethodId: string) => void;
};

type UiStates = 'loading' | 'ready' | 'submitting' | 'error';

export const Form: React.FC<Props> = ({
  returnUrl,
  ctaLabel = 'Continue',
  onSuccess,
  onUiStateChange,
}) => {
  const [uiState, setUiState] = useState<UiStates>('loading');
  const [errorMessage, setErrorMessage] = useState('');
  const [savePaymentMethod, setSavePaymentMethod] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  useBroadcast([uiState], onUiStateChange);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    setUiState('submitting');
    setErrorMessage('');

    const { setupIntent, error } = await stripe.confirmSetup({
      // Crashes during TS compilation, but fine in IDE o.O
      // @ts-ignore
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
      redirect: 'if_required',
    });

    if (error || typeof setupIntent?.payment_method !== 'string') {
      bugsnag.notify('Stripe returned error during confirmSetup', {
        metaData: { messageFromStripe: error?.message, setupIntent: setupIntent?.id },
      });
      setErrorMessage(error?.message || defaultErrorMessage);
      setUiState('error');
    } else {
      onSuccess(setupIntent.payment_method);
    }
  };

  useEffect(() => {
    if (!savePaymentMethod) {
      // @TODO
      // https://stripe.com/docs/payments/accept-a-payment?ui=elements#fetch-updates
    }
  }, [savePaymentMethod]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
      style={{ minHeight: 320 }}
      className='pos-rel container-fluid px-0 mb-0'
    >
      <div className='mb-1'>
        <PaymentElement
          onReady={() => setUiState('ready')}
          onChange={(event) => {
            if (event.complete) {
              setErrorMessage('');
            }
          }}
        />
      </div>
      <div className='row align-items-center'>
        <div className='col'>
          <ContentAnimator minHeight={43} state={uiState}>
            {uiState === 'error' && <div className='text-error fw-500'>{errorMessage}</div>}
          </ContentAnimator>
        </div>
        <div className='col-auto'>
          <button disabled={uiState === 'submitting'} className='btn btn--border-cta'>
            {uiState === 'submitting' ? 'Authorizing...' : ctaLabel}
          </button>
        </div>
      </div>
    </form>
  );
};
