import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPlaylistsByUser } from 'features/playlists/actions';
import { selectUserPublicPlaylists } from 'features/playlists/selectors';

import { PlaylistSlate } from 'components/playlists/PlaylistSlate';

import * as Types from 'types';

type Props = {
  userId: number;
};

export const UserPlaylists: FC<Props> = ({ userId }) => {
  const playlists = useSelector(selectUserPublicPlaylists(userId));

  const dispatch: Types.AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPlaylistsByUser(userId));
  }, []);

  return (
    <div className='col'>
      {playlists
        .filter(({ id }) => {
          const playlistId = parseInt(id, 10);
          return !Number.isNaN(playlistId);
        })
        .map((playlist) => {
          return <PlaylistSlate className='mb-3' key={playlist.id} playlist={playlist} />;
        })}
    </div>
  );
};
